:global .content-details .ato-refund .ant-table-content table thead th, :global .content-details .ato-refund .ant-table-content table tbody tr td{
    width: 20%;
    width: auto;
}
:global .content-details .ato-refund .ant-table-content table thead th:nth-child(1), :global .content-details .ato-refund .ant-table-content table tbody tr td:nth-child(1){
    /* width: 10%; */
}
:global .content-details .ato-refund .ant-table-content table thead th:nth-child(2), :global .content-details .ato-refund .ant-table-content table tbody tr td:nth-child(2){
    /* width: 30%; */
}
:global .content-details .ato-refund .ant-table-content table tbody tr td a{
    color: #0077C7;
}