@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css');

:root {
  --primary__color: #0077c7;
  --black__color: #000;
  --white_color: #fff;
  --text_color: #707070;
  --blue_color: #012f4e;
  --lightblue_color: #0078c8;
  --lightgrey_color: #777777;
}

* {
  font-family: 'Poppins';
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* .login-page{background: linear-gradient(270deg, rgba(1,106,183,1) 0%, rgba(0,69,133,1) 100%) !important;} */
.img-block {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f280;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary__color);
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary__color);
}

.pl-6 {
  padding-left: 60px !important;
}

.ant-form-item-label>label,
.ant-table {
  color: var(--black__color) !important;
}

.justify-center {
  justify-content: center;
}

.align-self {
  align-self: center;
}

/* body{overflow: hidden;} */
body input:focus,
body textarea:focus,
body select:focus,
body button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--text_color) !important;
}

body a {
  text-decoration: none;
  color: var(--text_color);
}

body a:hover {
  color: var(--blue_color);
}

body {
  background: var(--white_color);
}

body .ant-col {
  padding-left: 12px;
  padding-right: 12px;
}

body form .form-group {
  margin-bottom: 20px;
}

body form .form-control {
  border: 3px solid var(--text_color);
  border-radius: 30px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--text_color);
  padding: 15px 40px;
  font-size: 16px;
  line-height: 1;
}

body .login-img {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  height: 100vh;
  display: flex;
  align-items: flex-end;
}

body .login-btn {
  margin-top: 40px;
}

body .login-img img {
  margin: 0 0 0 11%;
  max-width: 100%;
}

.ant-btn.linkButton:hover {
  color: var(--blue_color);
}

body .login-form {
  display: flex;
  flex-direction: column;
  max-width: 396px;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: auto;
}

body .login-form .password {
  position: relative;
}

body .login-form .password .icon {
  position: absolute;
  cursor: pointer;
  right: 40px;
  top: 20px;
  font-size: 20px;
}

body .login-pages {
  margin: 0 0 0 -12px;
}

body .login-pages>.row {
  margin: 0;
}

body .login-form h1 {
  margin-top: auto;
  margin-bottom: 92px;
  font-size: 60px;
  letter-spacing: 0px;
  line-height: 1;
  position: relative;
  color: #404756;
  font-weight: 600;
}

body .login-form>img {
  margin-top: auto;
  margin-bottom: 40px;
}

body .footer-link {
  margin-top: 30px;
  margin-bottom: auto;
  display: flex;
  margin: 30px auto auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 70%;
  bottom: 30px;
  position: absolute;
  text-align: center;
}

.cms-page .footer-link {
  padding-top: 0;
  margin-bottom: 0;
  position: fixed;
  bottom: 30px;
  width: 100%;
  background: var(--white_color);
}

body .footer-link a {
  font-size: 16px;
  line-height: 26px;
}

/* body .footer-link a:after{content: '|'; margin: 0 5px;} */
body .footer-link a:last-child:after {
  display: none;
}

.sitelogo {
  display: flex;
  align-items: flex-end;
  grid-gap: 5px;
  color: var(--white_color);
}

.login-form .sitelogo {
  color: var(--primary__color);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.login-form .sitelogo img {
  width: 300px;
  filter: brightness(100);
}

.login-form .sitelogo span {
  padding-bottom: 13px;
  line-height: 1;
  color: var(--white_color);
}

body .login-form h1:before {
  width: 118px;
  height: 118px;
  background: #dedede;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: -30px;
  transform: translateY(-50%);
  top: 50%;
  z-index: -1;
}

body .login-form-block {
  background: linear-gradient(270deg, rgba(1, 106, 183, 1) 0%, rgba(0, 69, 133, 1) 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 54%;
  height: 100vh;
  color: var(--white_color);
}

body .login-form-block p a {
  color: var(--white_color);
}

body .login-form-block button[type='submit'] {
  background: #032c5d;
  border: #fff 2px solid;
  min-width: inherit;
  font: normal normal normal 18px/27px Poppins;
  padding: 0;
  min-width: 143px;
  height: 51px;
  min-height: inherit;
  text-transform: none;
}

body .login-form-block .linkButton {
  color: #fff !important;
}

body header {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  display: flex;
  padding: 0 15px;
  min-height: 65px;
  align-items: center;
  position: relative;
  z-index: 2;
}

body header img {
  height: 50px;
  filter: brightness(100);
}

body header .header-nav {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  grid-gap: 47px;
  position: absolute;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

body header .header-nav li {
  list-style: none;
  font-size: 18px;
  color: var(--white_color);
}

body header .header-nav li .dropdown {
  display: flex;
  align-items: center;
}

body header .header-nav li a {
  font-size: 18px;
  color: var(--white_color);
}

body header .header-nav li a:hover {
  opacity: 0.8;
}

body header .header-nav li ul {
  display: none;
}

body header .header-nav li .dropdown-toggle {
  min-width: inherit;
  height: auto;
  padding: 0;
  font-size: 18px;
  text-transform: none;
  background: none !important;
  box-shadow: none !important;
}

body header .header-nav li .dropdown-menu {
  background: var(--primary__color);
  border: 1px solid var(--primary__color);
  border-radius: 10px;
  box-shadow: none;
  padding: 5px 10px;
}

body header .header-nav li .dropdown-menu a {
  font-size: 14px;
  border-bottom: #fff 1px solid;
  padding: 5px 0;
}

body header .header-nav li .dropdown-menu a:last-child {
  border: none;
}

body header .header-nav li .dropdown-menu a:hover {
  background: none;
  color: var(--white_color);
}

body header h1,
.info-page .info-logo h1 {
  font-size: 25px;
  color: var(--white_color);
  font-weight: 600;
  margin: 0;
  position: relative;
  padding-left: 15px;
}

.info-page .info-logo {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  padding: 8px 0;
  text-align: center;
}

.info-page .info-logo img {
  text-align: center;
  z-index: 1;
  height: 50px;
  filter: brightness(100);
}

body header h1:before,
.info-page .info-logo h1:before {
  width: 49px;
  height: 49px;
  background: #b5afaf;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  z-index: -1;
}

.info-page .info-logo h1:before {
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -20px;
  background: #001727;
}

body header .userprofile {
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: 65px;
}

body header .userprofile .ant-image {
  background: var(--white_color);
  width: 49px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

body header .userprofile img {
  height: 49px;
  filter: none;
  border-radius: 50%;
  width: auto;
}

body header .userprofile .ant-image-mask {
  display: none;
}

body header .userprofile .btn {
  min-width: 90px;
  background: url(../images/user-icon.png) no-repeat 10px center !important;
  box-shadow: none !important;
  height: auto;
  min-height: inherit;
  border-radius: 0;
  font-size: 14px;
  padding: 0 30px 0 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}

body header .userprofile .btn span {
  background: var(--white_color);
  font-size: 36px;
  font-weight: 500;
  width: 49px;
  height: 49px;
  min-width: 49px;
  border-radius: 50%;
  color: var(--primary__color);
  text-transform: uppercase;
  display: flex;
  padding: 0;
  margin-right: 8px;
  line-height: 49px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  justify-content: center;
}

body header .userprofile .btn:after {
  float: right;
  margin-right: 0;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

body header .userprofile .dropdown {
  display: flex;
  min-height: 65px;
}

body header .userprofile .show.dropdown {
  background: var(--primary__color);
}

body header .userprofile .dropdown-menu {
  background: var(--primary__color);
  border: 1px solid var(--primary__color);
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  padding: 5px 10px;
  min-width: inherit;
  width: 100%;
  margin-top: -2px;
  text-align: center;
  color: var(--white_color);
  font-size: 14px;
}

body header .userprofile .dropdown-menu p {
  margin: 0 -10px;
  border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
  padding: 0 5px 5px;
}

body header .userprofile .dropdown-menu a {
  font-size: 14px;
  padding: 5px 0;
  color: var(--white_color);
}

body header .userprofile .dropdown-menu a:hover {
  background: none;
  color: var(--white_color);
  opacity: 0.8;
}

body .btn,
body .ant-btn.ant-btn-primary {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  font: normal normal normal 18px/27px Poppins;
  border: none;
  text-transform: uppercase;
  padding: 17px;
  min-width: 396px;
}

body .ant-btn-round.ant-btn-lg {
  min-height: 64px;
  font-size: 20px;
  padding: 0;
}

body .btn:hover,
body .ant-btn:hover {
  background: transparent linear-gradient(180deg, #06488b 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

body .form-control {
  border: 3px solid var(--text_color);
  border-radius: 30px;
  text-align: left;
  font: normal normal normal 20px/30px 'Poppins';
  letter-spacing: 0px;
  color: var(--text_color);
  opacity: 1;
  padding: 17px 40px;
  line-height: 1;
}

body .login-form p,
.login-form form small {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}

body .login-form .text-danger {
  font-size: 16px;
}

.login-form form .auth-text {
  background: url(../images/auth-mobile.png) no-repeat left center;
  filter: brightness(100);
  min-height: 63px;
  font-size: 16px;
  color: var(--white_color);
  text-align: center;
  padding: 6px 0 6px 45px;
  margin: 0 auto 28px;
  width: 396px;
}

.login-form form.ant-form .ant-form-item {
  border: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.login-form form.ant-form .ant-form-item.login-btn {
  padding-top: 20px;
  padding-bottom: 10px;
}

.login-form form.ant-form .ant-form-item input {
  border: 3px solid var(--text_color);
  border-radius: 30px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--text_color);
  padding: 15px 40px;
  font-size: 16px;
  line-height: 1;
  min-width: 396px;
  margin-bottom: 5px;
}

.login-form form.ant-form .ant-form-item input {
  border-color: var(--white_color);
}

.login-form form.ant-form .ant-form-item input:hover {
  border-right-width: 3px !important;
}

.login-form form.ant-form .ant-form-item.confirmButton button {
  /* background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; border-radius: 80px; opacity: 1; text-align: center; font: normal normal normal 18px/27px Poppins; letter-spacing: 0.25px; color: var(--white_color); min-width: 396px; min-height: 64px; padding: 0; border: none; text-transform: uppercase; margin-bottom: 10px; margin-top: 10px; */
  background: #032c5d;
  border: #fff 2px solid;
  min-width: inherit;
  font: normal normal normal 18px/27px Poppins;
  padding: 0;
  min-width: 143px;
  height: 51px;
  min-height: inherit;
  text-transform: none;
}

/* .login-form form.ant-form .ant-form-item.confirmButton button:hover{background: transparent linear-gradient(180deg, #06488B 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;} */
body .sidebar-nav {
  width: auto;
  float: left;
  height: calc(100vh - 114px);
  margin: 0;
  padding: 0;
  background: #005b98;
  position: relative;
  width: 58px;
}

body .sidebar-nav.open {
  width: auto;
}

body .sidebar-nav body .sidebar-nav .dropdown {
  display: flex;
  flex-direction: row-reverse;
}

body .sidebar-nav .sidebtn {
  font-size: 0;
  width: 25px;
  height: 25px;
  background: #001727 !important;
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  min-width: inherit;
  margin-right: 0;
  padding: 0;
  position: absolute;
  color: var(--white_color);
  font-style: normal;
  line-height: 25px;
  right: -26px;
  text-align: center;
  cursor: pointer;
  left: 15px;
  bottom: 15px;
  border-radius: 5px;
}

body .sidebar-nav .sidebtn:before {
  content: '\f101';
  font-family: 'FontAwesome';
  font-size: 14px;
}

body .sidebar-nav .dropdown-menu {
  position: relative !important;
  background: none;
  border: none;
  padding: 0;
  transform: none !important;
  min-width: 60px;
  display: block;
}

body .sidebar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: calc(100vh - 115px);
  /* overflow: auto; */
  counter-reset: my-sec-counter;
}

body .sidebar-nav ul li a {
  font-size: 12px;
  color: var(--white_color);
  font-weight: normal;
  background: #004c8040;
  display: block;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin: 1px 0 0;
  padding: 0 15px;
  font-size: 0;

}

body .sidebar-nav ul:hover li a::before {
  counter-increment: my-sec-counter;
  font-size: 14px;
  font-weight: normal;
  opacity: 1;
  position: absolute;
  right: 5px;
  content: counter(my-sec-counter);
  top: 5px;
  display: none;
}

body .sidebar-nav.open ul li a::before {
  display: none;
}

body .sidebar-nav.open ul li a {
  font-size: 12px;
}

body .sidebar-nav ul li a img {
  max-width: 27px;
}

body .sidebar-nav.open ul li a img {
  margin-right: 10px;
}

body .sidebar-nav ul li a:hover {
  background: none;
}

.defaultshow {
  display: block !important;
}

body .full-content-block {
  margin-left: 41px;
  /* height: calc(100vh - 185px); */
  height: calc(100vh - 114px);
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  overflow: hidden;
  padding-left: 15px;
  margin-right: 30px;
  padding-bottom: 0;
}

body .info-page .full-content-block {
  margin-left: 0;
}

body .info-page .full-content-block h1 {
  text-align: center;
  font-size: 38px;
  text-align: center;
}

body .full-content-block h2 {
  font-size: 26px;
  margin: 30px 0 0;
  font-weight: 600;
}

body .full-content-block>.col-md-12 {
  height: calc(100vh - 170px);
}

body .full-content-block .page-title {
  position: absolute;
  left: 15px;
  margin: 0;
  top: 64px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 50px;
}

body .full-content-block .page-title img {
  height: 30px;
  margin-left: 10px;
  margin-top: -4px;
}

body .full-content-block .page-title p {
  margin-right: 25px;
  cursor: pointer;
  color: var(--primary__color);
  margin-bottom: 0;
}

body .full-content-block .page-title p:hover {
  color: var(--black__color);
}

body .header-bottom-block {
  background: var(--white_color);
  min-height: 49px;
  box-shadow: 0px -2px 30px #00000029;
  display: flex;
  align-items: center;
}

body .header-bottom-block .searchbar {
  margin: 0 30px 0 auto;
  position: relative;
}

body .header-bottom-block .searchbar form {
  display: flex;
}

body .header-bottom-block .searchbar form .ant-select-selector {
  border: none;
  padding: 0;
}

body .header-bottom-block .searchbar form::before {
  content: '';
  width: 270px;
  height: 100%;
  position: absolute;
  z-index: 1;
}

body .header-bottom-block .searchbar form .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  width: 500px;
  height: 33px;
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  color: var(--black__color);
  font-size: 12px;
  padding: 0 20px;
  float: right;
  margin: 0 -15px;
  min-width: inherit;
}

.ant-select-item-option-content i {
  width: 25%;
  font-style: normal;
  display: inline-block;
}

.ant-select-item-option-content span {
  opacity: 0.8;
}

body .header-bottom-block .searchbar form .ant-select-selection-placeholder {
  float: right;
  line-height: 33px;
  margin-left: auto;
  display: table-row;
  display: flex;
  margin-left: 290px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
}

.editor-class.rdw-editor-main {
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-top: none;
  margin: -10px 0 30px;
}

.integration-card {
  display: flex;
  flex-flow: wrap;
  grid-gap: 50px;
}

.integration-card h3 {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary__color);
}

.integration-card .ant-card {
  width: 300px;
  border-radius: 20px;
  box-shadow: #00000009 0 20px 34px;
  padding: 20px;
}

.integration-card .ant-card-body {
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 170px;
  /* justify-content: center; */
  height: 100%;
  justify-content: space-between;
}

.integration-card .ant-card-body .crm-img {
  margin: -50px 0 40px;
  box-shadow: #00000016 0 3px 20px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: var(--white_color);
  overflow: hidden;
  text-align: center;
  position: relative;
}

.integration-card .ant-card-body .crm-img:before {
  width: 100%;
  height: 100%;
  border: 7px solid #fff;
  border-radius: 50%;
  content: '';
  position: absolute;
}

.integration-card .ant-card-body .crm-img img {
  width: 120px;
  height: 120px;
  margin: 10px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 -10px;
}

.integration-card .ant-card-body p {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 50px;
}

.integration-card .ant-card-body .btn {
  height: auto;
  min-width: inherit;
  color: var(--white_color);
  font-size: 14px;
  line-height: 1;
  padding: 12px 20px;
  opacity: 1 !important;
  visibility: visible !important;
  display: block !important;
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
}

body .header-bottom-block .searchbar form .btn {
  padding: 0;
  min-width: inherit;
  font-size: 12px;
  background: url(../images/search-icon.png) no-repeat center;
  font-size: 0;
  width: 20px;
  position: absolute;
  right: 10px;
  /* z-index: 1; */
  height: 33px;
}

body .header-bottom-block .global-time-period {
  margin-right: 15px;
}

body .header-bottom-block .global-time-period form {
  display: flex;
  align-items: center;
}

body .header-bottom-block .global-time-period form label {
  font-size: 14px;
  color: var(--black__color);
  white-space: nowrap;
  margin-right: 5px;
  margin-bottom: 0;
}

body .header-bottom-block .global-time-period form select {
  height: 33px;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  min-width: 135px;
  font-size: 14px;
}

.content-details {
  width: calc(100% - 30px);
  background: rgba(242, 242, 242, 0.5);
  padding: 50px 30px 30px;
  box-sizing: border-box;
  margin: 30px 15px;
  border-radius: 30px;
  max-height: calc(100vh - 174px);
  overflow: auto;
}

#supplier_modal .content-details {
  height: 100%;
  overflow: hidden;
}

form#supplierForm {
  max-height: calc(100vh - 174px);
  overflow: auto;
}

#supplier_modal ::-webkit-scrollbar {
  width: 6px !important;
}

#supplier_modal .action-box {
  clear: both;
  display: flex;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-bottom: 0;
}

#supplier_modal .modal-body .action-box button {
  margin-right: 0 !important;
  right: 0;
  bottom: 0;
  margin-left: 15px;
}

#supplier_modal .action-box button {
  border-radius: 30px;
}

.small-modal .modal-dialog .modal-body .content-details {
  margin-bottom: 0;
}

.mid-modal .modal-dialog {
  max-width: 768px;
}

.ant-table {
  border-radius: 20px !important;
}

.ant-pagination-item {
  background: none !important;
}

.content-details .table-top-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-details .table-top-btn .search-section .ant-select {
  min-width: 350px !important;
}

.content-details .table-top-btn .table-btn {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.content-details .table-top-btn .table-btn .border-btn {
  border: #ccc 2px solid;
  margin: 0 0 0 10px;
  border-radius: 30px;
  padding: 3px;
  display: flex;
}

/* .content-details .table-top-btn .table-btn .border-btn button{height: auto !important; font-size: 16px; line-height: 1.2;} */
.content-details .table-top-btn .table-btn .border-btn button.btn-link {
  padding: 0;
  margin: 0 5px 0 15px;
}

.content-details .table-top-btn h2 {
  color: var(--primary__color);
  margin: 0;
  font-size: 20px;
}

.content-details .table-top-btn .table-btn .btn-link,
.download-template.btn.btn-link {
  font-size: 16px;
  text-decoration: underline;
  background: unset;
  color: var(--primary__color);
  text-transform: none;
  padding: 0 25px;
  border-radius: 30px;
  /* height: 48px;
  line-height: 46px; */
  height: 30px;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 500;
  display: inline-block;
  min-width: inherit;
}

.content-details .table-top-btn .table-btn .btn-link:hover {
  background: unset !important;
}

.content-details .table-top-btn .table-btn button,
.content-details .table-top-btn .table-btn .button {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  /* font-size: 20px; */
  font-size: 18px;
  color: var(--white_color);
  text-transform: none;
  /* padding: 0 25px; */
  padding: 0 15px;
  border-radius: 30px;
  /* height: 48px;
  line-height: 46px; */
  height: 30px;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  display: inline-block;
  min-width: inherit;
}

.content-details .table-top-btn .table-btn a:hover,
.content-details .table-top-btn .table-btn button:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

.content-details .search-section {
  display: flex;
  align-items: center;
}

.content-details .search-section .ant-picker-range {
  border-radius: 30px;
}

.content-details .search-section a.action {
  font-size: 12px;
  /* border: #E2E2E2 1px solid; */
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  color: var(--white_color);
  padding: 0 25px;
  border-radius: 30px;
  height: 33px;
  line-height: 31px;
  margin-left: 5px;
  font-size: 16px;
}

.content-details .search-section a.action:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

.content-details .search-section form {
  position: relative;
  margin-right: auto;
}

.content-details .search-section form input {
  min-width: 464px;
  height: 33px;
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  color: var(--black__color);
  font-size: 12px;
  padding: 0 20px;
}

/* .content-details .search-section form input{min-width: 765px;} */
.content-details .search-section form .btn {
  padding: 0;
  min-width: inherit;
  font-size: 12px;
  background: url(../images/search-icon.png) no-repeat center;
  font-size: 0;
  width: 20px;
  position: absolute;
  right: 10px;
  z-index: 1;
  height: 33px;
  top: 0;
}

.content-details .grid-section {
  /* height: calc(100vh - 285px) !important; */
  margin: 30px 0 0;
}

.ag-center-cols-container,
.ag-header-container,
.ag-floating-top-container,
.ag-floating-bottom-container {
  width: 100% !important;
}

.ag-theme-alpine .ag-header {
  background: #f1f1f1;
  border-radius: 20px 20px 0 0;
}

.content-details .entry-card {
  border-radius: 30px;
  width: calc(50% - 15px);
  float: left;
  /* margin-bottom: 30px; */
}

.content-details .entry-card.full-card {
  width: 100%;
}

.content-details .entry-card:nth-child(2),
.content-details .entry-card:nth-child(3) {
  float: right;
}

.content-details .entry-card:nth-child(4) {
  /* clear: left; */
  float: right;
}

/* .audit-statement .ant-table-container table table tr th:nth-child(3), .audit-statement .ant-table-container table table tr td:nth-child(3){display: none;} */
.content-details .entry-card h3 {
  background: #eee;
  color: var(--black__color);
  font-size: 20px;
  font-weight: 500;
  border-radius: 30px 30px 0 0;
  line-height: 1;
  padding: 15px 30px;
  margin: 0;
}

.content-details .entry-card .form-box {
  padding: 30px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 2%;
  grid-row-gap: 20px;
  border-radius: 0 0 30px 30px;
  background: var(--white_color);
}

.content-details .entry-card .form-box .ant-form-item-control {
  padding-left: 0;
  padding-right: 0;
}

.content-details .entry-card .form-box .address-box {
  width: 100%;
  border: #eee 2px solid;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 15px;
}

.content-details .entry-card .form-box .address-box button {
  min-width: inherit;
  padding: 0;
  padding: 5px 20px;
  height: auto;
  border: var(--lightgrey_color) 2px solid;
  color: var(--black__color);
  background: none;
}

.content-details .entry-card .form-box .address-box .ant-form-item {
  width: calc(25% - 11.25px);
  padding: 0 !important;
}

/* .content-details .entry-card .form-box .address-box.firm-address .tetra-width {
  width: calc(25% - 15px);
} */
.content-details .entry-card .form-box .address-box.firm-address h5 {
  margin-bottom: 0;
}

.content-details .entry-card .form-box .address-box h5 {
  width: 100%;
  margin: 0;
}

.content-details .entry-card .form-box .address-box .half-width {
  padding-top: 10px;
}

.ant-btn.linkButton {
  box-shadow: none;
}

.date_field {
  white-space: nowrap;
}

.content-details .entry-card .form-box .image-box {
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 124px;
  flex-direction: column;
  margin: 0;
}

.content-details #customerForm .entry-card .form-box .image-box {
  margin-right: auto;
  width: auto;
}

.content-details #customerForm .entry-card .form-box .crop-block {
  font-size: 14px;
  color: var(--black__color);
  font-weight: 400;
  text-transform: capitalize;
  margin-left: 0;
}

.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-picture-card-wrapper {
  border: var(--text_color) 2px solid;
  border-radius: 10px;
  padding: 10px;
  display: block;
}

.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-picture-card-wrapper .ant-upload-list {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-start;
  margin-left: -10px;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  background: none;
  border: none;
}

.content-details #customerForm .entry-card .form-box .crop-block .ant-upload.ant-upload-select-picture-card {
  width: auto;
  height: 70px;
  border: var(--black__color) 1px solid;
  background: none;
  margin: 0;
  padding: 0 5px;
}

.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-list-picture-card .ant-upload-list-item,
.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-list-picture-card-container {
  padding: 0;
  height: 70px;
  width: 100%;
  margin: 0;
}

.content-details .entry-card .form-box .image-box input[type='file'] {
  width: 120px;
  height: 120px;
  border-width: 3px;
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
}

.content-details .entry-card .form-box .image-box button {
  /* height: 20px; font-size: 12px; padding: 0; width: 120px; border: none; background: rgba(0,119,199,0.47); color: var(--white_color); */
  position: absolute;
  bottom: 0;
  background: none !important;
}

.content-details .entry-card .form-box .input-boxes {
  width: 75%;
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
}

.content-details .entry-card .form-box.firm-form .input-boxes {
  width: calc(100% - 158px);
}

.content-details .entry-card .form-box .input-boxes .auth-form {
  width: 100%;
  border: #dedede 1px solid;
  border-radius: 20px;
  padding: 30px;
  background: #f1f1f1;
}

.content-details .entry-card .form-box .input-boxes .auth-form .auth-title {
  display: flex;
  align-items: center;
}

.content-details .entry-card .form-box .input-boxes .auth-form .auth-title h2 {
  margin: 0;
}

.content-details .entry-card .form-box .input-boxes .auth-form .auth-title .ant-form-item {
  margin: 0 0 0 auto !important;
  width: auto;
}

.content-details .entry-card .form-box .input-boxes .auth-form .auth-title .ant-btn {
  margin-left: auto;
  min-width: inherit;
  padding: 0 15px;
  color: var(--white_color);
  font-size: 14px;
  text-transform: none;
}

.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item.change-email {
  margin-top: 5px !important;
}

.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item.change-email button {
  margin-left: auto;
  min-width: inherit;
  padding: 0 15px;
  color: var(--white_color);
  font-size: 14px;
  text-transform: capitalize;
  background: #0078c890;
}

.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item {
  width: 100%;
  margin: 20px 0 0 !important;
}

.content-details .entry-card .form-box .input-boxes .auth-form h4 {
  margin: 5px 0 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}

.content-details .entry-card .form-box .form-label {
  font-size: 14px;
  margin: 0 0 5px;
  color: var(--black__color);
}

.content-details .entry-card .form-box .form-control,
.content-details .entry-card .form-box .form-select {
  border: var(--text_color) 1px solid;
  border-radius: 30px;
  color: var(--lightgrey_color);
  font-size: 14px;
  padding: 5px 15px;
}

.content-details .entry-card .form-box textarea.form-control {
  padding: 15px;
  border-radius: 20px;
}

.content-details .entry-card .form-box .half-width {
  width: 49%;
  grid-gap: 2%;
}

.content-details .entry-card .form-box .tri-width.fname-label,
.content-details .entry-card .form-box .tri-width.mname-label {
  width: calc(25% - 10.5px);
}

.content-details .entry-card .form-box .tri-width.lname-label {
  width: calc(33% - 10.5px);
}

.content-details .entry-card .form-box .title-label {
  width: 15%;
}

.content-details .entry-card .form-box .ant-picker {
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  width: 100%;
}

.content-details .entry-card .form-box .tetra-width {
  width: calc(25% - 12px);
}

.content-details .entry-card .form-box .tri-width {
  width: 33.33%;
}

.content-details .entry-card .form-box .full-width {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  position: relative;
}

.content-details .entry-card .form-box .full-width.address_block {
  grid-column-gap: 10px;
}

.content-details .entry-card .form-box .full-width.address_block label {
  width: 100%;
}

.content-details .entry-card .form-box .full-width.address_block input,
.content-details .entry-card .form-box .full-width.address_block select {
  width: calc(25% - 7.5px);
}

.action-box {
  clear: both;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.upload-btn-div {
  clear: both;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}

.action-box button {
  min-height: 49px;
  min-width: 143px;
  padding: 0;
  font-size: 20px;
  text-transform: none;
  margin-left: 5px;
  margin-right: 0;
}

.modal-body .action-box button {
  margin-right: 148px;
  margin-left: 0;
}

.action-box button.reset,
.modal-body button.reset {
  border: var(--lightgrey_color) 2px solid;
  color: var(--black__color);
  background: none;
  min-height: 49px;
  padding: 0;
  min-width: 143px;
  text-transform: none;
  font-size: 20px;
}

.action-box button.upload,
.modal-body button.upload {
  border: var(--lightgrey_color) 2px solid;
  min-height: 49px;
  padding: 0;
  min-width: 143px;
  text-transform: none;
  font-size: 20px;
  margin: 0 10px 0 0;
}

.modal-body .upload-btn-div .upload {
  border: none;
}

.upload-div {
  margin: 20px;
}

.upload-btn-div button.cancel-btn {
  margin: 0;
  border: var(--lightgrey_color) 2px solid;
  min-height: 49px;
  color: var(--black__color);
  background: none;
  padding: 0;
  min-width: 143px;
  text-transform: none;
  font-size: 20px;
  margin: 0 10px 0 0;
}

.ant-modal-body {
  padding: 0 !important;
  min-height: 60px;
}

.modal-body button.reset {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.action-box button.reset:hover,
.modal-body button.reset:hover {
  background: var(--lightgrey_color);
  color: var(--white_color);
}

body .ag-theme-alpine .ag-paging-panel {
  border: none;
  padding: 0;
}

body .ag-theme-alpine .ag-root-wrapper {
  border: none;
  background: transparent;
}

body .ag-theme-alpine .ag-header {
  background: #eee;
  color: var(--primary__color);
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px 30px 0 0;
  line-height: 1;
  margin: 0;
  border-color: #ececec;
}

body .ag-theme-alpine .ag-filter-toolpanel-header,
body .ag-theme-alpine .ag-filter-toolpanel-search,
body .ag-theme-alpine .ag-status-bar,
body .ag-theme-alpine .ag-header-row,
body .ag-theme-alpine .ag-panel-title-bar-title,
body .ag-theme-alpine .ag-multi-filter-group-title-bar {
  color: var(--primary__color);
  font-weight: 500;
}

body .ag-theme-alpine .ag-row {
  border-color: #ececec;
}

body .ag-theme-alpine .ag-row .ag-cell {
  border-right: #ececec 1px solid;
}

body .ag-theme-alpine .ag-row .ag-cell:first-child {
  border-left: #ececec 1px solid;
}

.content-details .customer-summary {
  display: flex;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 20px 34px #00000017;
  border-radius: 20px;
  justify-content: center;
  margin-bottom: 20px;
  background: var(--white_color);
}

.content-details .customer-summary .customer-summary-detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content-details .summary-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #005b98;
  text-transform: capitalize;
  font-weight: 500;
  width: auto;
  float: right;
  margin: 10px 0 20px;
  border: #00000017 solid 1px;
  padding: 10px 15px 10px 10px;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  background: var(--white_color);
}

.content-details .summary-filter svg {
  margin-left: 5px;
}

.content-details .customer-summary .balance {
  margin: 0 auto;
}

.content-details .customer-summary h2 {
  font-size: 30px;
  color: var(--primary__color);
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.content-details .customer-summary h6 {
  color: var(--primary__color);
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0 0;
}

.content-details .customer-summary .print-btn button {
  font-size: 20px;
  text-transform: none;
  padding: 0;
  min-width: 205px;
  min-height: 49px;
}

/* firm trust account balance card */
.firm-details {
  width: calc(100% - 10px);
  /* background: rgba(242, 242, 242, 0.5); */
  padding: 0;
  box-sizing: border-box;
  /* margin: 30px 15px; */
  border-radius: 30px;
  /* height: calc(100vh - 174px); */
  /* overflow: auto; */
}

.firm-details .firm-summary {
  display: flex;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 20px 34px #00000017;
  border-radius: 20px;
  margin-bottom: 20px;
  background: var(--white_color);
}

.firm-details .firm-summary .reconciliation-block {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  grid-gap: 15px;
}

.firm-details .firm-summary .reconciliation-block .btn {
  font-size: 20px;
  text-transform: none;
  padding: 0;
  min-width: 205px;
  min-height: 49px;
  color: var(--white_color);
}

.firm-details .firm-summary .balance {
  margin: 0 auto;
}

.firm-details .firm-summary h2 {
  font-size: 30px;
  color: var(--primary__color);
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.firm-details .firm-summary h6 {
  color: var(--primary__color);
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 8px;
}

.firm-details .firm-summary h5 {
  color: var(--primary__color);
  font-size: 28px;
  font-weight: 500;
  margin: 0;
}

.firm-details .firm-summary .print-btn button {
  font-size: 20px;
  text-transform: none;
  padding: 0;
  min-width: 205px;
  min-height: 49px;
}

/* .ag-theme-alpine .ag-header-icon{display: none;} */
body .ag-theme-alpine .ag-header-cell,
body .ag-theme-alpine .ag-header-group-cell,
body .ag-theme-alpine .ag-cell,
body .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 30px;
  padding-right: 30px;
}

body .ag-theme-alpine .ag-header-cell .ag-header-cell-resize {
  display: none;
}

/* body .ag-theme-alpine .ag-header-cell .ag-header-cell-resize{display: none;} */
.ag-theme-alpine .ag-row {
  color: var(--black__color);
  font-size: 16px;
}

.rag-green,
.rag-green::first-letter {
  color: #0da500;
}

.rag-red,
.rag-red::first-letter {
  color: #f00;
}

body .modal-backdrop {
  background: rgba(0, 119, 199, 0.47);
}

body .modal-dialog {
  max-width: 1024px;
}

body .detailpopup .modal-dialog .modal-header {
  background: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
}

body .modal-dialog.close-popup {
  max-width: 500px;
}

body .modal-dialog.close-popup .modal-content {
  border-radius: 15px;
  padding: 15px;
}

body .modal-dialog.close-popup .modal-content {
  padding: 30px;
}

body .modal-dialog.close-popup .modal-header {
  background: none;
  padding: 0;
  margin: 0;
}

body .modal-content {
  border-radius: 30px;
  border: none;
}

body .modal-dialog .modal-header {
  background: #eee;
  border-radius: 20px 20px 0 0;
  border: none;
  text-align: center;
  justify-content: center;
  color: var(--black__color);
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  margin-bottom: 30px;
}

body .modal-dialog .modal-header .btn-close {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 23px;
  background: var(--primary__color);
  opacity: 1;
  line-height: 1px;
  color: var(--white_color);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: 10px;
  top: 15px;
}

body .modal-dialog.close-popup .modal-header .btn-close {
  right: -18px;
  top: -18px;
  background: var(--blue_color);
}

body .modal-dialog .modal-header .btn-close:hover {
  background: #001727;
}

body .modal-dialog .modal-action {
  text-align: center;
  margin-bottom: 10px;
}

body .modal-dialog .modal-action .btn {
  min-width: inherit;
  font-size: 14px;
  line-height: 1;
  border-radius: 5px;
  padding: 7px 15px;
  margin: 0 3px;
}

body .modal-dialog .modal-action .btn.cancel {
  background: #999;
}

body .modal-dialog .modal-action .btn.cancel:hover {
  background: #001727;
}

.review-popup .btn {
  min-width: inherit;
  font-size: 14px;
  line-height: 1;
  border-radius: 5px;
  padding: 7px 15px;
  color: var(--white_color);
  margin: 0 3px;
  border-radius: 30px;
}

.review-popup .btn:hover {
  color: var(--white_color);
}

body .modal-dialog .modal-header .btn-close .btn {
  min-width: inherit;
  font-size: 16px;
  border-radius: 5px;
  padding: 7px 15px;
  line-height: 1;
  float: right;
}

body .modal-dialog .modal-header .btn-close:before {
  content: '\f00d';
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 18px;
}

body .icon {
  font-family: 'FontAwesome';
}

body .modal-body {
  padding: 0;
}

body .modal-body .content-details {
  background: transparent;
  padding: 0 30px 30px;
  height: auto;
  margin: 0;
  width: 100%;
}

body .modal-body .content-details .entry-card.full-card {
  width: 100%;
  margin-bottom: 10px;
}

body .modal-body .content-details .entry-card.full-card .form-box {
  padding: 0 0 20px;
  grid-column-gap: 2%;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width {
  width: 49%;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width div {
  justify-content: flex-start !important;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width div p {
  margin: 0;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width div a {
  color: #005b98;
  margin-left: 5px;
  font-weight: 500;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width a.action {
  background: #0078c885;
  color: var(--white_color);
  font-size: 12px;
  border-radius: 15px;
  padding: 8px 13px;
  display: inline-block;
  line-height: 1;
  margin-top: 5px;
}

body .modal-body .content-details .entry-card.full-card .form-box .half-width a.action:hover {
  background: var(--lightblue_color);
}

body .ant-modal-wrap {
  padding: 20px 0;
}

body .modal-body .modal-form {
  padding: 30px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
}

body .modal-body .remove-popup {
  text-align: center;
}

body .modal-body .remove-content p {
  font-size: 16px;
}

.activity-content {
  padding: 30px;
}

.activity-content .ant-table-column-title {
  color: #0077c7;
  font-size: 16px;
  font-weight: 600;
}

.remove-popup .ant-modal-footer button:first-child {
  border: var(--lightgrey_color) 2px solid;
  background: none;
  color: var(--black__color);
}

body .modal-body .modal-form input,
body .modal-body .modal-form select {
  border: var(--text_color) 1px solid;
  border-radius: 30px;
  color: var(--lightgrey_color);
  font-size: 14px;
  padding: 5px 15px;
}

body .modal-body .modal-form .half-width {
  width: calc(50% - 15px);
}

body .modal-body .modal-form .action-box {
  clear: both;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

body .modal-body .modal-form .action-box button {
  min-height: 49px;
  min-width: 143px;
  padding: 0;
  font-size: 20px;
  text-transform: none;
  margin-left: 5px;
}

body .modal-body .modal-form .action-box button.reset {
  border: var(--lightgrey_color) 2px solid;
  color: var(--black__color);
  background: none;
}

body .modal-body .modal-form .action-box button.reset:hover {
  background: var(--lightgrey_color);
  color: var(--white_color);
}

body .modal-body .modal-content-details {
  padding: 15px 30px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  /* margin-top: 40px; */
  /* align-items: center; justify-content: center; */
}

body .modal-body>.modal-content-details {
  margin-top: 40px;
}

body .modal-body .modal-content-details .action-block {
  display: flex;
  align-items: center;
}

body .modal-body .modal-content-details .action-block .btn.update-customer {
  margin-right: auto;
}

body .modal-body .modal-content-details .customer-account {
  margin-left: auto;
  align-items: flex-end;
  /* display: flex; flex-direction: column; */
  text-align: right;
  width: calc(100% - 150px);
  padding: 0;
  align-items: flex-end;
}

body .modal-body .modal-content-details .profile {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -20px;
  width: 150px;
  text-align: center;
}

body .modal-body .modal-content-details .profile.full {
  width: auto;
}

body .modal-body .modal-content-details .profile h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin: 12px 0 0;
  color: var(--primary__color);
  text-transform: capitalize;
}

body .modal-body .modal-content-details .profile .ant-image {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  border: var(--text_color) 2px solid;
}

body .modal-body .modal-content-details .profile>span {
  width: 114px;
  height: 114px;
  background: var(--primary__color);
  border-radius: 50%;
  color: var(--white_color);
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

body .modal-body .modal-content-details .profile img {
  width: 100%;
  height: 110px;
  border-radius: 50%;
  max-width: 100%;
}

body .modal-body .modal-content-details .profile img:after {
  content: '';
}

.content-details .role-table .ant-table-content table thead th {
  width: auto;
}

.ant-image-mask-info {
  display: flex;
  align-items: center;
}

body .modal-body .modal-content .modal-content-details {
  padding-bottom: 5px;
  align-items: flex-start;
}

body .modal-body .modal-content .modal-content-details .modal-content-left,
body .modal-body .modal-content .modal-content-details .modal-content-right {
  width: 50%;
}

/* .page-content-block{height: calc(100vh - 110px); overflow: auto;} */
body .modal-body .modal-content-details p {
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 500;
  display: flex;
  grid-gap: 5px;
}

body .modal-body .modal-content-details p i {
  float: right;
  font-style: normal;
  margin-left: auto;
}

body .modal-body .modal-content-details p span {
  float: right;
  display: flex;
  align-items: center;
  margin-left: auto;
}

/* body .modal-body .modal-content-details p span.refund_block:before{width: 16px; height: 16px; border: var(--black__color) 2px solid; content: ''; margin-right: 5px; opacity: 0.85; border-radius: 50%;} */
body .modal-body .modal-content-details p strong,
body .modal-body .modal-content-details p.deceased:first-letter {
  font-weight: 500;
  color: var(--primary__color);
  white-space: nowrap;
}

body .modal-body .modal-content-details p.address-box strong {
  min-width: 165px;
}

body .modal-body .modal-content h3 {
  background: #f9f9f9;
  color: var(--black__color);
  font-size: 20px;
  line-height: 1;
  padding: 10px 30px;
  margin: 0;
}

body .modal-body .modal-content-details a {
  color: var(--primary__color);
  font-size: 18px;
  line-height: 1;
  margin-bottom: 20px;
  text-decoration: underline;
  display: inline-block;
}

body .modal-body .modal-content-details a.text-link {
  margin: 0 20px 20px;
}

body .modal-body .modal-content-details a.authority-link {
  font-size: 12px;
  text-decoration: underline;
  color: var(--black__color);
}

body .modal-body .modal-content-details a:hover {
  color: var(--black__color);
}

body .modal-body .modal-content-details .btn {
  min-width: 162px;
  height: 38px;
  font-size: 16px;
  padding: 0 15px;
  text-transform: none;
  line-height: 38px;
  color: #fff !important;
  text-decoration: none;
  margin-left: 20px;
  margin-bottom: 0;
}

body .modal-body .modal-content-details .btn.small-btn {
  font-size: 12px;
  border: #e2e2e2 1px solid;
  background: rgba(0, 119, 199, 0.47);
  color: var(--white_color);
  text-transform: uppercase;
  padding: 0 15px;
  border-radius: 30px;
  height: 33px;
  line-height: 31px;
  margin-left: 5px;
  min-width: inherit;
}

body .modal-body .modal-content-details .btn.small-btn:hover {
  background: rgba(0, 119, 199, 1);
}

body .modal-body .modal-content-details .btn.update-customer {
  float: left;
  margin-left: 0;
}

body .modal-body .modal-content-details h4 {
  font-size: 22px;
  margin: 0 0 10px 0;
  color: var(--primary__color);
  line-height: 1;
}

.deleted-clients .ant-table-thead th:first-child {
  width: 2%;
}

.customer_list .ag-body-viewport .ag-row .ag-cell:nth-child(2)::first-letter,
.user_list .ag-body-viewport .ag-row .ag-cell:nth-child(1)::first-letter,
.deleted-clients .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(3)::first-letter,
td.fullname::first-letter,
.client-select .ant-select-item-option-content::first-letter,
.client-select .ant-select-selection-item::first-letter,
.all-client-name::first-letter {
  background: rgba(0, 69, 116, 0.4);
  font-size: 14px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  color: var(--white_color);
  text-transform: uppercase;
  display: block;
  padding: 0 5px;
  margin-right: 8px;
}

.ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(3) .ag-cell-label-container .ag-header-cell-label,
.tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(4) .ag-cell-label-container .ag-header-cell-label,
.ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(1) .ag-cell-label-container .ag-header-cell-label,
.tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(1) .ag-cell-label-container .ag-header-cell-label {
  justify-content: center;
}

.ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:last-child .ag-cell-label-container .ag-header-cell-label,
.tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:last-child .ag-cell-label-container .ag-header-cell-label {
  justify-content: flex-end;
}

.error {
  color: red;
}

.ant-select-dropdown {
  z-index: 99999 !important;
}

body .full-content-block .ant-tabs {
  width: 100%;
  margin: 30px 0 0 !important;
}

body .full-content-block .aba-tabs .ant-tabs {
  margin-top: 0 !important;
}

body .full-content-block .ant-tabs .ant-table-thead>tr>th {
  color: var(--primary__color);
  font-size: 16px;
  background: #f1f1f1;
}

body .full-content-block .ant-tabs .ant-tabs-content-holder {
  background: #f2f2f280 0% 0% no-repeat padding-box;
  border-radius: 0px 30px 30px 30px;
  padding: 30px;
  overflow: auto;
  height: calc(100vh - 231px);
}

body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-table-wrapper {
  margin-top: 0;
}

body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder {
  padding: 0;
  background: none;
}

body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder .task-table {
  padding: 0;
  margin: 0;
}

.fa-edit,
.fa-eye,
.fa-share-square,
.fa-trash {
  cursor: pointer;
}

body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder .task-table .ant-table-content table thead tr th:first-child {
  border-radius: 0;
}

body .full-content-block .ant-tabs .ant-tabs-content-holder .cms-page .table-btn {
  text-align: right;
  margin-bottom: 30px;
}

.ant-table-tbody>tr:last-child>td {
  border: none;
}

/* body .full-content-block .ant-tabs .ant-tabs-content-holder .cms-page .table-btn .btn {
  min-width: 205px;
  min-height: 49px;
  padding: 0;
  text-transform: none;
  position: relative;
} */
body .full-content-block .ant-tabs .ant-tabs-nav {
  margin: 0;
}

body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  background: var(--white_color);
  border: 1px solid #f1f1f1;
  border-radius: 20px 20px 0px 0px;
  padding: 0 15px;
  min-width: 149px;
  text-align: center;
  height: 50px;
  justify-content: center;
  font-size: 16px;
  color: var(--black__color);
  font-weight: 500;
  margin-right: 5px;
}

body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs {
  margin-top: -30px !important;
}

body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs-tab {
  border-radius: 10px 10px 0 0;
  margin-right: 5px;
}

body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover,
body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #88bfe5;
  color: var(--white_color);
}

body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn,
body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white_color);
}

body .ant-tabs-ink-bar {
  display: none;
}

body .ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

body .ant-table-row-expand-icon {
  border: none;
}

body .ant-table-row-expand-icon:before {
  font-family: 'FontAwesome';
  content: '\f105';
  transform: none;
  top: 50%;
  height: auto;
  background: none;
  left: 50%;
  right: auto;
  font-size: 18px;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
}

body .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded:before {
  transform: rotate(90deg) translate(-3px, -8px);
}

body .ant-table-row-expand-icon:after {
  display: none;
}

body .ant-table-tbody>tr>td {
  border-color: #ececec;
}

body .ant-table-tbody>tr>td span a {
  border-bottom: 1px dashed;
}

body .ant-table-tbody>tr>td:last-child svg {
  font-size: 16px;
}

body .ant-pagination-prev .ant-pagination-item-link,
body .ant-pagination-next .ant-pagination-item-link {
  background: none;
}

body .ant-pagination-prev .ant-pagination-item-link .anticon,
body .ant-pagination-next .ant-pagination-item-link .anticon {
  display: flex;
  align-items: center;
}

body .ant-pagination-next .ant-pagination-item-link .anticon-right::before {
  content: 'Next';
  display: block;
  padding-right: 5px;
}

body .ant-pagination-prev .ant-pagination-item-link .anticon-left::after {
  content: 'Prev';
  display: block;
  padding-left: 5px;
}

body .firm-form {
  display: flex;
}

body .firm-form .ant-upload-list-picture:before {
  border: none;
}

body .firm-form .ant-upload-list-picture .ant-upload-list-item {
  border-radius: 5px;
}

body .firm-form .ant-upload-list-picture .ant-upload-list-item img {
  border-radius: 0;
  padding: 5px;
}

body .firm-form .image-box span.ant-upload {
  border-radius: 0 0 5px 5px;
}

body .firm-form .image-box {
  margin-right: 50px;
  text-align: center;
  position: relative;
}

body .firm-form .input-boxes {
  width: calc(100% - 180px);
}

.ant-tabs-tabpane .content-details {
  background: var(--white_color);
  margin: 0;
  height: auto;
  padding: 30px;
  width: 100%;
  max-height: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* .ant-pagination-disabled{display: none !important;} */
.authority-letter .ant-image-mask {
  display: none;
}

.ant-input-number {
  width: 100% !important;
  border-radius: 20px !important;
}

.centeralign {
  text-align: center !important;
}

.spin-padding {
  padding-top: 20px;
}

.ant-input-number-handler {
  border: none !important;
}

.ant-input-number {
  overflow: hidden;
}

body .ant-btn.undo_btn:hover {
  background: var(--white_color);
}

/* .ant-table-column-title{white-space: nowrap;} */
[data-title]:after {
  content: attr(data-title);
  background: var(--white_color) 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 10px #00000029;
  white-space: nowrap;
  font: normal normal normal 14px/23px 'Poppins';
  color: var(--black__color);
  z-index: 9999999;
  padding: 5px 15px;
  line-height: 1;
  display: none;
  position: absolute;
  left: 100%;
}

[data-title]:hover:after {
  transition: all 0.1s ease 0.5s;
  display: block;
}

[data-title] {
  position: relative;
}

[data-title]:after {
  content: attr(data-title);
  background: var(--white_color) 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 10px #00000029;
  white-space: nowrap;
  font: normal normal normal 14px/23px 'Poppins';
  color: var(--black__color);
  z-index: 9999999;
  padding: 5px 15px;
  line-height: 1;
  display: none;
  position: absolute;
  left: 100%;
}

[data-title]:hover:after {
  transition: all 0.1s ease 0.5s;
  display: block;
}

[data-title] {
  position: relative;
}

.content-details .search-section.process-search {
  margin-bottom: 30px;
}

.content-details .search-section.process-search .ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 30px !important;
}

.content-details .search-section.process-search .ant-input-search-button {
  padding: 0;
  min-width: inherit;
  font-size: 12px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAABHNCSVQICAgIfAhkiAAAAXFJREFUOE+lUz1LxEAUnNX6asFetNBSi4ug2Ahi4UdnY6FgI1h4FxuttIp3iNYKFrZ+gCDYiAdZCwUrEfUXmFbru3U2u0n2zEU4DGwg783Mm/f2ReAfj8hxK3IKPRjO4uoWwfh7pxoZWZMEDnkcoqUoNCDU2m8RQ/Yf5gB1maorvBD8AQiPsT7HxZArIOCH/QQ9W1CEFpZQ8+5Sgh8uM38af2vRfW8kyQlU5Tat7saBJsZQ955y/bkCEPMIylcao8n3fE9Q9oKWFguH78tP406dELdqyL5U1tIOLe0Vk8NzwhdovUHcZEI2igp1BiuF5A4O9cCOqbhCUkRLA7T03aHnQWLebHwDgXdkKm/KUfTi0SSyfpxpl6DEtZlLjEmvy95zWl1ntYMtNMUrN22apHUzqPhpc5eQS0wcWPuFbdvB6gUq6/badzvetNasIxJxkGc8N3Qwo5t0BfI/xl91q7LmCnRH1sKZQNQ9WQvEN6S+fgCxB4Tq+23mqwAAAABJRU5ErkJggg==) no-repeat center;
  font-size: 0;
  width: 20px;
  position: absolute;
  right: 10px;
  z-index: 1;
  height: 33px;
  top: 0;
  border: none;
}

.content-details .search-section.process-search .ant-input-search-button span {
  opacity: 0;
}

.ant-select-dropdown {
  padding: 0 !important;
}

.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-group {
  background: #f1f1f1;
  color: var(--primary__color);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  line-height: 1;
}

.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped {
  color: var(--black__color);
  font-size: 14px;
  padding: 7px 15px;
  border-bottom: #f1f1f1 1px solid;
}

.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped.optionlist .ant-select-item-option-content::first-letter {
  background: rgba(0, 69, 116, 0.4);
  font-size: 14px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  color: var(--white_color);
  text-transform: uppercase;
  display: block;
  padding: 0 5px;
}

.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped.ant-select-item-option-active {
  background: rgba(0, 119, 199, 0.19);
}

.ant-select-dropdown .rc-virtual-list-holder .viewmore {
  color: var(--primary__color);
}

.dashboard {
  width: 100%;
  text-align: center;
  position: relative;
}

.dashboard .dashboard-pager {
  /* position: absolute; */
  /* right: 120px; */
  /* right: 6px; */
  /* top: 29px; */
  color: var(--black__color);
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.dashboard .dashboard-pager .ant-select-selector {
  border-radius: 20px !important;
  min-width: 140px;
  min-height: 36px;
  border: #e2e2e2 1px solid !important;
  margin-left: 5px;
}

.dashboard .dashboard-pager .ant-select-selector .ant-select-selection-item {
  /* text-indent: -10px; */
  line-height: 34px;
  color: var(--black__color);
  text-align: justify;
  font-weight: 400;
}

.dashboard .ant-tabs>.ant-tabs-nav {
  position: absolute;
  right: 0;
  /* top: 25px; */
  top: 0px;
}

body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab,
.reconcile-tab .ant-tabs-tab {
  min-width: 41px !important;
  height: 41px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background: var(--white_color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #ececec !important;
  text-indent: -9999em;
}

body .reconcile-tab.ant-tabs .ant-tabs-nav {
  margin: -35px 0 15px;
}

body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab::before,
.reconcile-tab .ant-tabs-tab::before {
  font-family: 'FontAwesome';
  content: '\f03a';
  line-height: 1;
  font-size: 18px;
  text-indent: 0em;
}

body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab:first-child:before,
.reconcile-tab .ant-tabs-nav-list .ant-tabs-tab:first-child:before {
  content: '\f00a';
}

body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab:hover,
body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.reconcile-tab .ant-tabs-nav-list .ant-tabs-tab:hover,
.reconcile-tab .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #ececec 0% 0% no-repeat padding-box !important;
  color: var(--primary__color) !important;
}

body .full-content-block .dashboard .ant-tabs .ant-tabs-content-holder {
  padding: 0;
  background: none;
  border-radius: 0;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item {
  border: none;
  margin-bottom: 1px;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:first-child .accordion-button {
  border-radius: 10px 10px 0 0;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:last-child .accordion-collapse,
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:last-child .accordion-button.collapsed {
  border-radius: 0 0 10px 10px;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-header {
  margin: 0;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-header button {
  background: #f2f2f280;
  color: var(--primary__color);
  border-radius: 0;
  font-weight: 700;
  border: none;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse {
  margin: 0 0 -1px;
  border: #f2f2f280 1px solid;
  border-radius: 0;
  border-top: none;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body {
  padding: 0;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accordion-button::after {
  /* background-color: var(--white_color); */
  background-size: 18px;
}

.ant-table-content table .ant-table-tbody tr.ant-table-row:hover td .ant-table-row-expand-icon {
  background: transparent;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li {
  border-bottom: #e7f1ff 1px solid;
  padding: 10px 15px;
  display: flex;
  flex-flow: wrap;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .crn {
  min-width: 320px;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .crn span:last-child {
  margin-left: 0px !important;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li:hover {
  background: #f0f0f0;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li:last-child {
  border-bottom: none;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .card-footer {
  margin-left: auto;
  padding: 0 40px 0 0;
  background: none;
  border: none;
  margin: 0 0 0 auto;
}

body .full-content-block .dashboard h2 {
  margin-bottom: 30px;
}

.dashboard .amount {
  font-weight: bold;
}

.dashboard .grid-view {
  display: flex;
  grid-gap: 30px;
}

.dashboard .grid-view .dashboard-grid {
  background: #f2f2f280 0% 0% no-repeat padding-box;
  border-radius: 30px;
  width: 25%;
  position: relative;
}

.dashboard .grid-view .dashboard-grid.nodata:after {
  content: '';
  background: url(../images/empty-board.svg) no-repeat center center;
  width: 60%;
  height: 241px;
  opacity: 0.2;
  position: absolute;
  top: 50px;
  left: 50%;
  background-size: 100%;
  transform: translateX(-50%);
}

.dashboard .grid-view .dashboard-grid:nth-of-type(1):before {
  background-color: rgb(249, 202, 25);
}

.dashboard .grid-view .dashboard-grid:nth-of-type(2):before {
  background-color: rgb(62, 168, 243);
}

.dashboard .grid-view .dashboard-grid:nth-of-type(3):before {
  background-color: rgb(236, 29, 36);
}

.dashboard .grid-view .dashboard-grid:nth-of-type(4):before {
  background-color: rgb(81, 218, 110);
}

.dashboard .grid-view .dashboard-grid:before {
  content: '';
  width: calc(100% - 60px);
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dashboard .grid-view h3 {
  font: normal normal 600 18px/27px 'Poppins';
  letter-spacing: 0px;
  color: var(--lightblue_color);
  text-align: center;
  margin: 25px 0 15px;
}

.dashboard .grid-view ul {
  /* padding: 15px; */
  padding: 0px 15px;
  list-style: none;
  /* height: calc(100vh - 315px); */
  height: calc(100vh - 380px);
  overflow: auto;
}

.dashboard .grid-view ul li {
  background: var(--white_color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 15px;
}

.dashboard .grid-view ul li:hover {
  /* box-shadow: 0px 20px 34px #00000017; */
  box-shadow: 0px 10px 10px #00000017;
}

.dashboard ul li p {
  text-align: left;
  letter-spacing: 0px;
  color: var(--black__color);
  display: flex;
  margin: 0 0 10px;
  align-items: center;
}

.dashboard ul li p.refund-p {
  font: normal normal normal 14px/23px 'Poppins';
  letter-spacing: 0px;
  color: var(--primary__color);
  margin: 0;
}

.dashboard ul li p.comments {
  margin: 5px 0;
}

.dashboard ul li p.username {
  margin: 0;
}

.dashboard ul li p.username svg {
  margin-right: 8px;
}

.dashboard ul li .card-footer {
  background: var(--white_color);
  border-top: var(--text_color) 1px solid;
  padding: 10px 0 0;
  margin: 10px 0 0;
  background: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.dashboard ul li .card-footer .dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.dashboard ul li .card-footer .dropdown .dropdown-toggle {
  min-width: inherit;
  padding: 0;
  margin-top: -16px;
}

.dashboard ul li .card-footer .dropdown .dropdown-toggle:after {
  display: none;
}

.dashboard ul li .card-footer .dropdown .dropdown-menu {
  background: var(--white_color) 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 20px #00000038;
  border: 2px solid #99b5c7;
  border-radius: 10px;
  padding: 0;
  width: 140px;
}

.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item {
  padding: 7px 10px;
  font-size: 14px;
  line-height: 1;
  border-bottom: #99b5c7 2px solid;
}

.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item:last-child {
  border: none;
}

.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--primary__color);
  background: none;
}

.dashboard ul li .card-footer .dropdown .fa-ellipsis-v {
  cursor: pointer;
  position: absolute;
  right: 0;
  font-size: 18px;
  color: var(--primary__color);
  z-index: 1;
}

.dashboard ul li p.refund-p span {
  margin-left: auto;
  font-weight: 500;
}

.dashboard ul li .card-footer svg {
  margin-right: 5px;
}

.dashboard ul li .card-footer .firstchar {
  background: rgba(0, 69, 116, 0.4);
  font-size: 14px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  color: var(--white_color);
  text-transform: uppercase;
  display: block;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 8px;
}

.dashboard .list-view ul li p {
  margin: 0;
  min-width: 12%;
  padding: 0 20px 0 0;
}

.dashboard .list-view ul li p.username {
  text-transform: capitalize;
}

.dashboard .list-view ul li .card-footer .dropdown .fa-ellipsis-v {
  margin: 0;
  top: 3px;
}

.dashboard .list-view ul li .card-footer .firstchar {
  margin-right: 0;
}

.dashboard .list-view ul li p.refund-p span {
  margin-left: 10px;
}

.ant-upload-list-item .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.consent-form.ant-form-vertical .ant-form-item {
  width: 100%;
}

.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control {
  padding: 0;
}

.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper {
  border: 1px solid #f1f1f1;
}

.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper .rdw-editor-toolbar {
  border: none;
  border-bottom: #f1f1f1 1px solid;
  display: none;
}

.authority-letter {
  padding: 15px;
}

.authority-letter.letter-page {
  max-width: 1170px;
  margin: 0 auto 30px;
  border: 1px solid #ccc;
}

.authority-letter .auth-head {
  display: flex;
  flex-flow: wrap;
}

.authority-letter .auth-head h2 {
  color: #005b98;
  font-size: 26px;
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
}

.authority-letter .auth-head .auth-logo {
  margin: 0 50px 0 auto;
  color: var(--black__color);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1;
}

.authority-letter.letter-page h2 {
  margin: 0;
}

.authority-letter.letter-page .auth-logo {
  margin-right: 0;
}

.authority-letter .auth-head .auth-logo {
  height: 80px;
  width: auto;
}

.authority-letter .auth-head .auth-logo .ant-image-mask {
  display: none;
}

.authority-letter .auth-head p {
  font-weight: 700;
  margin: 0 0 30px;
  width: 100%;
}

.authority-letter .auth-client-details {
  margin-bottom: 20px;
}

.authority-letter .auth-client-details .client-detail-table {
  border: #333 1px solid;
  border-bottom: none;
}

.authority-letter .auth-client-details .client-detail-table p {
  margin: 0;
  display: flex;
  flex-flow: wrap;
  border-bottom: #333 1px solid;
  color: #333;
  line-height: 30px;
  padding: 0 10px;
}

.authority-letter .auth-client-details .client-detail-table p label {
  width: 32%;
  text-align: right;
  border-right: #333 1px solid;
  padding: 0 5px 0 0;
  margin-right: 5px;
  font-weight: 600;
}

.authority-letter .auth-client-details .client-detail-table div {
  float: left;
  width: 100%;
}

.authority-letter .auth-client-details .client-detail-table div p {
  float: left;
  display: block;
  width: 32%;
}

.authority-letter .auth-client-details .client-detail-table div p:first-child {
  width: 55%;
}

.authority-letter .auth-client-details .client-detail-table div p:last-child {
  width: 13%;
}

.authority-letter .auth-client-details .client-detail-table div p:last-child label {
  width: auto;
}

.authority-letter .auth-client-details .client-detail-table div p:first-child label {
  width: 59.1%;
}

.authority-letter .auth-client-details .client-detail-table div.account-div p:first-child {
  width: 60%;
}

.authority-letter .auth-client-details .client-detail-table div.account-div p:first-child label {
  width: 54%;
}

.authority-letter .auth-client-details .client-detail-table div.account-div p:last-child {
  width: 40%;
}

.authority-letter .office-use-only {
  margin-top: 50px;
}

.authority-letter .office-use-only .client-detail-table {
  border: #333 1px solid;
  border-bottom: none;
}

.authority-letter .office-use-only .client-detail-table p {
  margin: 0;
  display: flex;
  flex-flow: wrap;
  border-bottom: #333 1px solid;
  color: #333;
  line-height: 30px;
  padding: 0 10px;
}

.authority-letter .office-use-only .client-detail-table p label {
  width: 40%;
  text-align: right;
  border-right: #333 1px solid;
  padding: 0 10px 0 0;
}

.authority-letter .office-use-only .client-detail-table div {
  width: 100%;
  display: flex;
}

.authority-letter .office-use-only .client-detail-table div p {
  width: 33.33%;
}

.authority-letter .office-use-only .client-detail-table div p label {
  width: auto;
}

.authority-letter h4,
.authority-letter .auth-client-details h4 {
  color: #001727;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 5px;
  line-height: 1;
  text-transform: uppercase;
}

.authority-letter h4 strong {
  font-weight: 700;
}

.authority-letter .signature-section {
  display: flex;
  flex-flow: wrap;
  grid-gap: 15px;
  margin-top: 50px;
}

.authority-letter .signature-section .signature {
  width: calc(30% - 15px);
}

.authority-letter .signature-section .print-name {
  width: calc(50% - 15px);
}

.authority-letter .signature-section .date {
  width: calc(20% - 15px);
}

.authority-letter .signature-section p {
  width: 100%;
  margin: 10px 0 0;
}

.authority-letter .signature-section .signature-block i {
  font-style: normal;
  border-top: #333 1px solid;
  display: block;
  font-weight: 600;
}

.authority-letter .signature-section .signature-block p {
  margin: 0;
  min-height: 23px;
}

.authority-letter .authority-footer {
  margin-top: 30px;
  padding-top: 30px;
  border-top: #001727 2px solid;
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
}

.authority-letter .authority-footer p {
  margin: 0;
}

.authority-letter .authority-footer div {
  width: calc(25% - 30px);
}

.authority-letter .authority-footer .company-logo {
  width: auto;
  margin: 0 0 0 auto;
}

.authority-letter .authority-footer .company-logo img {
  height: 50px;
}

.printbtn {
  min-width: inherit !important;
  height: auto !important;
  line-height: 1 !important;
  width: 140px !important;
  font-size: 15px !important;
  display: inline-block !important;
  margin: 0 5px;
}

.dashboard .list-view {
  height: calc(100vh - 231px);
  overflow: auto;
}

.address-block {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  grid-gap: 2%;
}

.address-block h5 {
  width: 100%;
}

.popup-without-header .ant-modal-body {
  padding: 0 30px 30px !important;
}

.popup-without-header .ant-modal-body h4 {
  padding: 25px 0 10px;
  font-size: 18px;
}

.popup-without-header .ant-modal-body .ant-picker-range {
  width: 100%;
  border-radius: 30px;
}

body .modal-dialog.small-popup {
  max-width: 500px;
}

body .modal-dialog.small-popup .modal-body {
  padding-bottom: 30px;
  /* min-height: 305px; */
}

body .modal-dialog.small-popup .modal-body .upload-div {
  padding: 0 20px 20px;
  width: 100%;
  text-align: center;
  margin: 0;
}

body .modal-dialog.small-popup .modal-body .upload-div .upload-btn-div {
  justify-content: center !important;
}

body .modal-dialog.small-popup .modal-body .or {
  position: relative;
  font-weight: 700;
  font-style: italic;
  width: calc(100% - 40px);
  text-align: center;
  z-index: 1;
}

body .modal-dialog.small-popup .modal-body .or::before {
  width: 100%;
  background: #00000029;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}

body .modal-dialog.small-popup .modal-body .or::after {
  content: '';
  background-color: var(--white_color);
  height: 100%;
  z-index: -1;
  position: absolute;
  width: 40px;
  top: 0;
  height: 100%;
  left: calc((100% - 40px) / 2);
}

body .ant-input-affix-wrapper {
  border-radius: 30px;
}

.authority-letter ul {
  margin: 0;
  padding: 0 0 0 15px;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-toggle {
  background: none;
  color: #00000080;
  padding: 0 0 0 15px;
  margin: 0;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-toggle::after {
  font-family: 'FontAwesome';
  content: '\f05a';
  border: none;
  background: none;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu {
  width: 340px;
  left: 0;
  background: #005b98;
  border: none;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu::before {
  font-family: 'FontAwesome';
  content: '\f0d8';
  position: absolute;
  top: -24px;
  font-size: 26px;
  left: 15px;
  color: #005b98;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a {
  background: none !important;
  font-size: 14px;
  white-space: normal;
  letter-spacing: normal;
  font-weight: 500;
  color: var(--white_color);
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a p {
  margin-bottom: 5px;
}

.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a p:last-child {
  margin-bottom: 0;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  line-height: 14px;
}

/***** 2FA Setup *****/
.content .ant-steps {
  padding: 50px 30px 30px;
  max-width: 600px;
  margin: 0 auto;
}

.step-page-btn {
  text-align: center;
}

.step-page-btn button {
  background: none !important;
  border: none;
  color: var(--black__color) !important;
  padding: 0;
  height: auto;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}

.step-page-btn button span {
  display: flex;
  grid-gap: 5px;
}

.step-page-btn button span:after {
  content: '\f105';
  font-family: 'FontAwesome';
}

.content .steps-content {
  border-radius: 20px;
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 30px;
  display: flex;
  box-shadow: #00000016 0 0 10px;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
}

.content .steps-content .steps-content {
  margin: 0 !important;
  background: none;
  box-shadow: none;
  padding: 0;
}

.content .steps-content h5 {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  margin: 0 0 15px;
  line-height: 1.2;
}

.step1,
.step2,
.step3 {
  align-items: stretch;
  grid-gap: 5px;
  margin-bottom: 15px;
}

.content .steps-content p {
  color: var(--black__color);
  padding: 0;
  line-height: 1.2;
  margin: 0 0 15px;
  font-size: 16px;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.content .steps-content .ant-input {
  margin-bottom: 15px;
  border-radius: 30px;
}

.content .steps-content button {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  font: normal normal normal 18px/27px Poppins;
  border: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 16px;
  padding: 10px 25px;
  height: auto;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  color: var(--white_color);
  font-weight: 500;
  min-width: inherit;
}

.content .steps-content .ant-image-mask {
  display: none;
}

.content .steps-content button:hover {
  background: transparent linear-gradient(180deg, #06488b 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

.firm-form .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.firm-form .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img {
  max-height: 120px;
  height: auto;
  width: auto;
  max-width: 120px;
}

header .sitelogo {
  font-size: 12px;
  line-height: 1;
}

header .sitelogo span {
  position: relative;
  bottom: 5px;
}

/***** 2FA Setup *****/

@media (max-width: 1600px) {
  body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs {
    margin-top: 30px !important;
  }
}

@media (max-width: 1366px) {
  .content-details .entry-card .form-box {
    padding: 15px;
  }

  body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    min-width: inherit;
    height: 40px;
    border-radius: 15px 15px 0 0;
  }
}

@media (max-width: 992px) {
  body header .header-nav {
    grid-gap: 20px;
  }

  body .header-bottom-block .searchbar form input {
    min-width: 50%;
  }

  .content-details {
    padding: 20px;
  }

  body .login-img {
    height: auto;
  }

  .login-pages .ant-row {
    flex-direction: column-reverse;
  }

  .login-pages .ant-row .ant-col,
  body .login-form-block {
    width: 100%;
    min-width: 100%;
  }
}

@media print {

  .authority-letter .ant-image-mask,
  .ant-btn.btn,
  .header-blocks,
  .sidebar-nav,
  .ant-tabs-nav,
  .action-box,
  .page-title,
  .consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper .rdw-editor-toolbar {
    display: none !important;
  }

  .ant-tabs-content-holder,
  .content-details {
    padding: 0 !important;
  }

  .authority-letter.letter-page {
    max-width: 100% !important;
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    overflow: visible !important;
    transform-origin: center top;
  }

  body .full-content-block {
    padding: 0 !important;
    margin: 0 !important;
  }

  .consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper {
    border: none !important;
  }

  .auth-head {
    display: none;
  }

  .authority-letter ul {
    padding: 0 !important;
    margin: 0 !important;
  }
}

body .modal-dialog.medium-popup {
  max-width: 500px;
}

body .modal-dialog.medium-popup .content-details {
  text-align: center;
  padding-bottom: 30px;
}

body .modal-dialog.medium-popup .action-box {
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

body .modal-dialog.medium-popup .action-box button {
  margin-right: 0;
}

body .modal-dialog.medium-popup.review-popup .half-width {
  width: 100% !important;
  text-align: left;
}

body .ant-form label {
  color: var(--black__color);
}

body .ant-pagination-item,
body .ant-pagination-prev,
body .ant-pagination-next,
body .ant-pagination-jump-prev,
body .ant-pagination-jump-next {
  height: auto;
  line-height: 1;
}

body .ant-table-pagination.ant-pagination a {
  line-height: 1;
}

#customerForm .entry-card,
#loginForm .entry-card,
#editUserForm .entry-card {
  margin-bottom: 30px;
}

.form-box.photos-section .ant-upload-list-picture:before,
#loginForm .form-box .ant-upload-list-picture:before {
  background: #7db5db url(../images/blank-profile.webp) no-repeat center;
  background-size: 100%;
  width: 120px;
  height: 120px;
}

.form-box.photos-section .ant-upload-list-picture span.ant-upload {
  width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1 !important;
  background: var(--white_color);
  border-radius: 50%;
}

.firm-form .ant-form-vertical .ant-form-item {
  width: 100%;
}

body .login-form.firm-form .input-boxes {
  width: 100%;
}

body .login-form.firm-form form.ant-form .ant-form-item input[type='text'] {
  position: relative;
  background-color: var(--white_color);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  padding: 0 11px;
  font-size: 14px;
  border-radius: 30px !important;
}

body .login-form.firm-form form.ant-form .ant-form-item input[type='text']:hover {
  border-width: 1px !important;
}

body .login-form.firm-form form.ant-form .ant-form-item input[type='text']:focus {
  border-color: #40a9ff !important;
}

body .login-form.firm-form {
  min-width: 60%;
}

body .login-form.firm-form .ant-form {
  width: 100%;
  width: 60%;
}

body .login-form.firm-form .ant-form .firm-form-box {
  grid-gap: 15px;
  border: 2px solid #eee;
  border-radius: 10px;
  display: flex;
  flex-flow: wrap;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
}

body .login-form.firm-form .ant-form .firm-form-box h3 {
  font-size: 1.25rem;
  width: 100%;
  margin: 0;
}

body .login-form.firm-form .ant-form .firm-form-box .ant-form-item {
  text-align: left;
  width: calc(50% - 7.5px);
}

body .login-form.firm-form .ant-form .firm-form-box .ant-form-item .ant-col {
  padding: 0;
}

body .login-form.firm-form .ant-form .firm-form-box .ant-form-item .ant-col input[type='text'] {
  min-width: 100%;
}

.subscription-plan .col-lg-6 button {
  min-width: inherit;
  line-height: 1;
  padding: 10px 30px;
  color: var(--white_color);
  font-size: 14px;
}

.subscription-plan .col-lg-6 button {
  background: #0077c770;
  clear: left;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  line-height: 1;
  font-size: 17px;
  box-shadow: 0 7.84314px 19.6078px rgb(73 204 249 / 30%);
  text-transform: none;
  margin: 0 0 25px;
  font-weight: 500;
}

.subscription-plan .col-lg-6 button:disabled {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
}

.subscription-plan .col-lg-6 button:hover {
  color: var(--white_color);
  background: var(--primary__color);
}

.subscription-plan .payment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
}

.subscription-plan .payment-btn button {
  color: var(--white_color);
  height: auto;
  min-width: inherit;
  line-height: 1;
}

.subscription-plan .payment-btn button:disabled {
  color: var(--black__color);
  border: #666 1px solid;
  font-weight: 500;
}

.credit-card .ant-form-item-control {
  border: 1px solid #d9d9d9 !important;
  border-radius: 30px;
  padding: 0 10px !important;
}

.congrats-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.congrats-block h3 {
  background: url(../images/congrats.gif) no-repeat center top;
  padding: 190px 0 0;
}

.congrats-block .btn {
  min-width: inherit;
  height: auto;
  padding: 10px 30px;
  line-height: 1;
  color: #fff !important;
  font-size: 16px;
}

.subscription-notfound {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.subscription-notfound h3 {
  background: url(../images/lock.gif) no-repeat center top;
  padding: 170px 0 0;
}

.subscription-notfound .btn {
  min-width: inherit;
  height: auto;
  padding: 10px 30px;
  line-height: 1;
  color: #fff !important;
  font-size: 16px;
}

.card-div {
  width: calc(100% - 30px);
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}

.card-right-content {
  float: right;
}

.card {
  display: flex;
}

.tab-div {
  display: flex;
}

.subscription-plan .list-group-item {
  font-size: 13px;
  color: var(--black__color);
  border: none;
  font-weight: 500;
  padding: 3px 0;
}

/* .subscription-plan .list-group-flush{min-height: 210px;} */
.subscription-plan .col-lg-6 {
  background: var(--white_color);
  box-shadow: 0 15px 35px rgb(16 30 54 / 40%);
  border-radius: 12px;
  height: auto;
  /* width: calc(22% + 2px); */
  width: calc(25%);
  border: none;
  margin: 0 15px 0;
  -webkit-box-flex: 0;
  border-top: none;
  border-bottom: 4px solid var(--primary__color);
}

.subscription-plan .col-lg-6 .card-body {
  position: relative;
  padding: 1rem 15px 15px;
}

.custom-tabs>div p.saleoffer {
  background: #012f4e;
  display: inline-flex;
  border-radius: 0 0 10px 10px;
  color: #fff;
  padding: 3px 10px;
  width: auto;
  align-items: center;
  margin: 0 0 20px;
}

.subscription-plan .col-lg-6 .card-body .coupon-title {
  position: absolute;
  top: 0;
  width: auto;
  background: var(--primary__color);
  margin: 0;
  color: var(--white_color);
  font-weight: 700;
  border-radius: 0 0 5px 5px;
  left: 50%;
  padding: 5px 15px;
  transform: translateX(-50%);
}

.custom-tabs {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.custom-tabs h6.support-tab::before {

  /* .custom-tabs h6.monthly-tab::before { */
  border: #fff 1px solid;
  border-radius: 60px;
  height: 49px;
  content: '';
  /* width: 235px; */
  width: 423px;
  display: block;
  position: absolute;
  left: 0;
}

.subscription-plan.firm-setting .custom-tabs h6.monthly-tab::before {
  display: none;
}

.custom-tabs h6.monthly-tab::before {
  border: #fff 1px solid;
  border-radius: 60px;
  height: 49px;
  content: '';
  /* width: 235px; */
  width: 282px;
  display: block;
  position: absolute;
  left: 0;
}

.custom-tabs h6 {
  position: relative;
  width: auto;
  max-width: inherit;
  margin: 0;
  cursor: pointer;
  padding: 0;
  font-size: 18px;
  /* width: 117px; */
  width: 141px;
  height: 47px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--white_color);
  border-radius: 60px;
}

.custom-tabs h6.active {
  background: var(--white_color);
  color: #005b98;
}

.custom-tabs>div {
  width: 100%;
  margin-top: 30px;
  /* position: absolute; */
  top: 200px;
  float: left;
  /* flex-flow: wrap; */
  max-width: 1200px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.subscription-plan .custom-tabs>div {
  max-width: 100%;
}

.custom-tabs>div p {
  width: 100%;
  margin-top: 15px;
  color: var(--black__color);
}

.subscription-plan .custom-tabs>div h2 {
  margin-top: 10px;
}

.custom-tabs>div h2.h2.stricked-price {
  font-size: 1rem;
}

.yearly {
  display: none;
}

.supplier-modal .action-box {
  margin: 20px 0;
  justify-content: flex-end !important;
}

.supplier-modal .modal-body button.reset {
  position: relative;
  right: 20px;
}

.mid-modal .input-boxes {
  display: flex;
  flex-flow: wrap;
  grid-gap: 15px;
}

.mid-modal .input-boxes .half-width {
  width: calc(50% - 7.5px);
}

.mid-modal .input-boxes .full-width {
  width: 100%;
}

.mid-modal .input-boxes .ant-row .ant-col {
  padding: 0;
}

.promo-features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.promo-features p {
  margin: 0;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-top: #ccc 1px solid;
}

.apply-block {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: #ccc 1px solid;
}

.apply-block strong {
  font-weight: 700;
}

.promo-field {
  display: flex;
  align-items: center;
  width: 100%;
}

.promo-field input {
  width: 73%;
}

.promo-field button {
  padding: 9px 25px !important;
  font-size: 16px !important;
  height: auto;
  line-height: 1 !important;
  color: #fff !important;
  font-weight: 500;
  background: #0077c770 !important;
  min-width: 25% !important;
  float: right;
}

.promo-field button:disabled {
  font-size: 16px !important;
  font-weight: 500;
  cursor: not-allowed;
}

.promo-field button:hover {
  color: var(--white_color);
}

body .ant-btn.ant-btn-primary:disabled {
  color: #fff !important;
}

body .ant-btn.ant-btn-primary:disabled:hover {
  color: rgba(0, 0, 0, 0.35) !important;
}

.subscription-plan p {
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--black__color);
}

.subscription-plan p label {
  font-weight: 600;
}

.subscription-plan h6 {
  font-weight: 700;
}

.firm-login .firm-form-box {
  background: var(--white_color);
  box-shadow: #00000073 0 10px 30px;
}

.subscription-plan {
  background: url(../images/subscription-bg.png) no-repeat center top;
}

.subscription-plan .ant-spin-dot-item {
  background-color: var(--white_color);
}

.subscription-plan .spinloader {
  display: flex;
  width: 100vw;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.subscription-plan .spinloader .ant-spin {
  transform: scale(2);
}

/* .firm-login:after{background: url(../images/subscription-bg.png) no-repeat center top; position: absolute; right: 0; bottom: 0; content: ''; width: 50%; height: 40%; background-size: 100%; z-index: -1; transform: rotate(180deg);} */
.firm-login .ant-form-item .ant-checkbox-wrapper span {
  color: var(--white_color);
}

.firm-login .ant-form-item .ant-checkbox-wrapper span a {
  color: var(--white_color);
  text-decoration: none;
  border-bottom: 1px dotted var(--white_color);
  position: relative;
  z-index: 1;
}

.firm-login .action-box button {
  background: none;
  border: #fff 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.firm-login .action-box button svg {
  margin-left: 10px;
}

.custom-tabs>div.notice {
  background: #0077c720;
  font-weight: 500;
  color: var(--black__color);
  padding: 5px 10px;
  border: #0077c7 1px solid;
  border-radius: 5px;
  border-left-width: 5px;
  text-align: left;
  justify-content: flex-start;
}

.content-details.description-block {
  padding-top: 10px;
}

.content-details.description-block h3 {
  color: var(--primary__color);
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
}

.content-details.description-block li h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.refund-process .bank-reconcile-grid .refund-process-box .container .refund-top-details {
  margin-bottom: 0;
}

.solid-btn {
  background: #0078c885 !important;
  color: var(--white_color);
  font-size: 12px;
  border-radius: 15px !important;
  padding: 8px 13px;
  display: inline-block;
  line-height: 1;
}

.ant-form-item-control-input .ant-select {
  width: 100%;
}

.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

body .login-form-block.trans-login {
  background: none;
  color: var(--black__color);
}

body .login-form-block.trans-login p a {
  color: var(--black__color);
}

body .login-form-block.trans-login .login-form .sitelogo img {
  filter: none;
}

body .login-form-block.trans-login .login-form .sitelogo span {
  color: var(--primary__color);
}

body .login-form-block.trans-login .login-form form.ant-form .ant-form-item input {
  border-color: var(--text_color);
}

body .login-form-block.trans-login button[type='submit'],
.trans-login .login-form form.ant-form .ant-form-item.confirmButton button {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  padding: 17px;
  min-width: 396px;
  height: auto;
  border: none;
  font-size: 20px;
  line-height: 1;
}

body .login-form-block.trans-login button[type='submit']:hover,
.trans-login .login-form form.ant-form .ant-form-item.confirmButton button:hover {
  background: transparent linear-gradient(180deg, #06488b 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

body .login-form-block.trans-login .linkButton {
  color: var(--primary__color) !important;
}

.trans-login .login-form form .auth-text {
  filter: none;
  color: var(--black__color);
}

body .login-img.white-site {
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

body .login-img.white-site img {
  filter: brightness(100);
  margin: 0;
  max-width: 420px !important;
}

.audit-statement .ant-table a {
  color: var(--primary__color);
}

div#basiq-control {
  max-height: 420px;
  overflow: auto;
}

body .full-content-block .firm-trust .ant-tabs .ant-tabs-content-holder {
  height: calc(100vh - 441px);
  /* height: auto; */
}

div#basiq-control {
  max-height: 70vh;
}

.review-popup .cdi-page-content,
.cdi-subtitle {
  display: none;
}

#basiq-control .vc-container {
  width: 100%;
}

.content-details .setting-tabs .ant-table-content table thead tr th:first-child {
  border-top-left-radius: 0 !important;
}

.content-details .setting-tabs .ant-table-content table {
  border-radius: 0 20px 20px 20px;
}

body .full-content-block .ant-tabs.setting-tabs .ant-tabs-content-holder {
  height: calc(100vh - 380px);
}

.icon-info {
  margin-left: 10px;
}

.icon-info:before {
  display: none;
}

.icon-info .nav-link {
  padding: 0;
}

.icon-info .nav-link:after {
  border: none;
  float: left;
  margin: -4px 0 0 5px !important;
  background: url(../images/bulb-icon.jpg) no-repeat center;
  text-indent: -9999em;
  background-size: 100%;
  width: 30px;
  height: 40px;
}

.icon-info .dropdown-menu {
  width: 25vw;
  padding: 0;
  margin-top: 5px;
}

.icon-info .dropdown-menu .close {
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
}

.icon-info .dropdown-item:active {
  background: #e9ecef;
}

.icon-info .dropdown-item.information {
  white-space: normal !important;
  max-height: calc(100vh - 160px) !important;
  overflow: auto;
}

.icon-info .dropdown-item p {
  color: #000 !important;
  font-size: 14px;
}

.content-details .table-top-btn.trial-btn .dropdown-item:hover {
  color: #fff;
}

body .full-content-block .ant-tabs.trial-balance .ant-tabs-content-holder {
  height: calc(100vh - 382px);
}

.table-top-btn .pdf-btn {
  margin-left: auto;
}

.table-top-btn .pdf-btn a {
  min-width: inherit;
  font-size: 14px;
  color: #fff;
  padding: 12px 25px;
  line-height: 1;
  text-transform: none;
  line-height: 1;
  margin-left: 10px;
}

/**** ADMIN STYLE ****/
.admin-dashboard {
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
  padding: 30px;
  background: #f0f2f9;
  height: calc(100vh - 114px);
  align-items: flex-start;
}

.admin-dashboard .halfwidth {
  width: calc(33.33% - 20px);
}

.admin-dashboard .admin-box {
  border-radius: 20px;
  padding: 30px;
  background: #fff;
  box-shadow: #ccc 0 0 1px;
  position: relative;
  display: flex;
  flex-flow: wrap;
}

.admin-dashboard .admin-box a {
  display: flex;
  flex-flow: wrap;
}

.admin-dashboard .admin-box .icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-dashboard .admin-box .icon svg {
  height: 7em;
  width: auto;
}

/* .admin-dashboard .admin-box .icon svg path{fill: #ff6301;} */
.admin-dashboard .admin-box h4 {
  color: #005b98;
  font-size: 18px;
  opacity: 0.7;
  width: 100%;
  padding-bottom: 5px;
}

.admin-dashboard .admin-box h2 {
  width: 100%;
}

.admin-dashboard .admin-box p {
  margin: auto 0 0;
}

.table-btn .dropdown-menu {
  padding: 0;
}

.content-details .table-top-btn .table-btn .dropdown-menu button {
  display: block;
  width: 100%;
  padding: 0;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background: transparent;
  border: 0;
  line-height: normal;
  height: auto;
  font-size: 16px;
  margin: 0;
}

.content-details .table-top-btn .table-btn .dropdown-menu .dropdown-item:hover button {
  color: #fff;
}

.add-all-clients {
  float: right;
  margin: 0 0 10px;
}

.add-all-clients button {
  font-size: 12px;
  border: #e2e2e2 1px solid;
  background: rgba(0, 119, 199, 0.47);
  color: var(--white_color);
  text-transform: uppercase;
  padding: 0 15px;
  border-radius: 30px;
  font-weight: 600;
  height: 33px;
  line-height: 31px;
  margin-left: 5px;
  min-width: inherit;
}

.add-all-clients button:hover {
  background: rgba(0, 119, 199, 1);
  color: #fff;
}

body .full-content-block.admin-table .ant-tabs .ant-tabs-content-holder {
  height: auto;
}

.partial-registered th,
.partial-registered td {
  white-space: nowrap;
}

th.wrapword,
td.wrapword {
  white-space: inherit !important;
}

th.breakcolumn,
td.breakcolumn {
  white-space: inherit !important;
}

body .full-content-block.admin-table .content-details {
  padding: 30px;
}

body .modal-dialog.medium-popup-bank {
  max-width: 850px;
}

body .modal-dialog.mid-popup {
  max-width: 600px;
}

.medium-popup-bank .remove-content table {
  border: #ccc 1px solid;
  margin: 0 15px 15px;
  width: calc(100% - 30px);
  border-radius: 10px;
  border-collapse: inherit;
}

.medium-popup-bank .remove-content tbody tr td {
  padding: 10px;
  border-right: #ccc 1px solid;
  border-bottom: #ccc 1px solid;
}

.medium-popup-bank .remove-content tbody tr td.nodata {
  font-size: 18px;
  padding: 30px;
  border: none;
}

.medium-popup-bank .remove-content thead tr th {
  padding: 10px;
  background: #0077c7;
  color: #fff;
  color: #fff;
  font-weight: 500;
  border-right: #fff 1px solid;
}

.medium-popup-bank .remove-content thead tr th:last-child {
  border-right: 0;
  border-top-right-radius: 10px;
}

.medium-popup-bank .remove-content thead tr th:first-child {
  border-top-left-radius: 10px;
}

.medium-popup-bank .remove-content tbody tr td:first-child {
  border-bottom-left-radius: 10px;
}

.medium-popup-bank .remove-content tbody tr td:last-child {
  border-bottom-right-radius: 10px;
  border-right: none;
}

.medium-popup-bank .remove-content tbody tr:last-child td {
  border-bottom: none;
}

.medium-popup-bank .remove-content tbody tr {
  cursor: pointer;
}

.medium-popup-bank .remove-content tbody tr:nth-child(2n) td {
  background: #f0f0f0;
}

div[aria-labelledby='contained-modal-title-vcenter removebank-popup'] {
  background: rgba(0, 0, 0, 0.5);
}

body .modal-body .remove-content.bank-content {
  display: flex;
  gap: 30px;
  flex-flow: wrap;
  padding: 0 30px 30px;
  overflow: auto;
  max-height: 80vh;
}

body .modal-body .remove-content.bank-content .remove-card {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  border: #ccc 1px solid;
  padding: 15px;
  background: #f0f0f0;
  position: relative;
  cursor: pointer;
}

body .modal-body .remove-content.bank-content .remove-card h5,
body .modal-body .remove-content.bank-content .remove-card p {
  color: #0077c7;
  font-size: 16px;
}

body .modal-body .remove-content.bank-content .remove-card p {
  font-size: 14px;
}

body .modal-body .remove-content.bank-content .remove-card h5 {
  padding-right: 25%;
}

body .modal-body .remove-content.bank-content .remove-card i {
  font-weight: 700;
  font-style: normal;
  font-weight: 700;
  border-top: #ccc 1px solid;
  width: 100%;
  display: block;
  padding: 5px 0 0;
}

body .modal-body .remove-content.bank-content .remove-card span {
  color: #000;
}

body .modal-body .remove-content.bank-content .remove-card .status {
  position: absolute;
  right: 15px;
  top: 15px;
  background: #001727;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  padding: 3px 7px;
}

.modal-backdrop,
.modal {
  z-index: 2 !important;
}

.mid-popup .search-section {
  padding: 0 30px 30px;
  text-align: right;
}

.mid-popup .search-section .ant-picker {
  border-radius: 30px;
}

.ant-spin {
  width: 100%;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  min-width: 100%;
  background: none;
  font-size: 14px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input:focus {
  background: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.content-details .entry-card .form-box input[type='password'] {
  border-radius: 0 !important;
}

.insecure-content {
  padding: 3em;
}

.insecure-content svg {
  height: 30px;
  fill: #333;
}

.insecure-content .icon {
  border: 3px solid #333;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.insecure-content h2 {
  font-size: 30px;
}

.insecure-content p {
  font-size: 16px;
  max-width: 580px;
}

.insecure-content a {
  min-width: inherit;
  color: var(--white_color);
  border-radius: 7px;
  text-transform: none;
  background: var(--blue_color);
  line-height: 1;
  padding: 12px 20px;
  font-size: 16px;
  text-shadow: none;
}

.insecure-content a:hover {
  background: var(--primary__color);
  color: var(--white_color);
}

#customerForm .ant-form-item .ant-row {
  flex-direction: column;
}

.ant-checkbox+span {
  white-space: nowrap;
}

.ant-form-item .ant-row {
  flex-direction: column;
}

.ant-space-item {
  cursor: pointer;
}

span.import-status {
  text-align: left;
  font-size: 15px;
}

span.import-status strong {
  color: var(--primary__color);
  display: inline-block;
  margin-right: 5px;
}

.table-top-btn.reconcile-list {
  margin-top: 30px;
  background: #eee;
  padding: 10px;
  display: flex;
  border-radius: 20px;
}

body .modal-body .modal-content-details.reconcile-list {
  display: block;
}

.table-top-btn.reconcile-list input[type='text'] {
  border: none;
  min-height: 34px;
  min-width: 500px;
  padding: 0 10px;
  background: #fff url(../../assets/images/search-black.png) no-repeat 98% center;
  border-radius: 20px;
}

.reconcile-list-table {
  padding: 15px 30px;
}

.disabled-font-awesome {
  color: grey;
  cursor: not-allowed !important;
}

.reconcile-tab .ant-tabs-nav-wrap {
  justify-content: flex-end;
}

.table-action-btn .fa-money-bill-transfer {
  background: url(../images/reconcile.png) no-repeat;
  background-size: 20px;
  font-size: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.table-action-btn .fa-money-bill-transfer:hover {
  background: url(../images/reconcile-active.png) no-repeat;
  background-size: 20px;
}

.table-action-btn .fa-paper-plane {
  background: url(../images/review.png) no-repeat;
  background-size: 16px;
  font-size: 0;
  width: 16px;
  height: 16px;
}

.table-action-btn .fa-money-bill-transfer path,
.table-action-btn .fa-money-bill-transfer title,
.table-action-btn .fa-memory path,
.table-action-btn .fa-memory title,
.table-action-btn .fa-paper-plane path,
.table-action-btn .fa-paper-plane title {
  display: none;
}

.table-action-btn .disabled-font-awesome {
  opacity: 0.7;
}

svg.svg-inline--fa.fa-ellipsis-vertical.fa-w-4.fa-1x {
  color: #0077c7;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 17px;
  top: 5px;
  z-index: 1;
}

.ant-table-cell .ant-select {
  width: 100% !important;
}

span.salesforce-and-xero {
  display: flex;
}

span.salesforce-and-xero img {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}

span.salesforce-and-xero h5 {
  padding: 40px 0 40px 0;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0;
  margin-top: 0;
  color: var(--black__color);
  font-size: 16px;
}

.remove-content.tenant .ant-select {
  margin-bottom: 1em;
  width: 50%;
}

.remove-content.tenant .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.remove-content.tenant .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: left;
  padding: 0px 8px 0px 8px;
}

a.action.solid-process-refund-btn {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  color: var(--white_color);
  font-size: 20px;
  /* border-radius: 15px !important; */
  border-radius: 30px;
  /* padding: 8px 13px; */
  padding: 8px 25px;
  display: inline-block;
  line-height: 1;
}

a.action.solid-process-refund-btn:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
  /* color: #0078c885 !important; */
  /* border: 1px solid #0078c885; */
}

h5.right-alignment {
  text-align: right;
}

.jounal-entry-card {
  background: #42275a05;
  border-radius: 30px;
  padding: 30px;
  margin-bottom: 20px;
  border: var(--businessprimary-color) 1px solid;
}

.jounal-entry-card-completed {
  background: #00fb2205;
  border-radius: 30px;
  padding: 30px;
  margin-bottom: 20px;
  border: #129d00 1px solid;
}

.journal-entry-status {
  border-radius: 20px;
  box-shadow: #00000009 0 20px 34px;
  padding: 20px;
  background: #fff;
  border: #f1f1f1 1px solid;
  gap: 20px;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  flex-flow: wrap;
}

.journal-entry-status p {
  margin: 0;
  font-size: 18px;
}

.journal-entry-status p i {
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.journal-table input[type='text'] {
  border: hsl(0, 0%, 80%) 1px solid;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}

.journal-entry-status form {
  margin-right: auto;
}

.journal-entry-status form .rs-picker-toggle {
  justify-content: center;
  text-transform: uppercase;
}

.journal-table .selectbox__placeholder {
  white-space: nowrap;
}

.jounal-entry-card .action-box .reset:hover,
.jounal-entry-card-completed .action-box .reset:hover {
  color: #fff !important;
}

.journal-entry {
  display: flex;
  gap: 20px;
}

.journal-table table {
  border-radius: 15px;
  table-layout: auto;
  overflow: hidden;
}

.journal-table table thead tr th {
  background: #f1f1f1;
  color: #0077c7;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 20px;
  border-right: #ccc 1px solid;
  border-bottom: none;
  border-top: #ccc 1px solid;
}

.journal-table table tbody tr td {
  padding: 11px 20px;
  border-right: #ececec 1px solid;
  border-bottom-color: #ececec;
  word-break: break-all;
  background: #fff;
}

.journal-table table tbody tr td svg {
  cursor: pointer;
}

.journal-table table thead tr th:first-child {
  border-top-left-radius: 15px;
}

.journal-table table thead tr th:last-child {
  border-top-right-radius: 15px;
  border-right: none;
}

.journal-table table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.journal-table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

a.mailto-tel {
  color: #333;
}

.infinity-scroll-component-container {
  width: 100%;
}

svg.svg-inline--fa.fa-plus.fa-w-14 {
  cursor: pointer;
}

.receipt {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  color: var(--white_color);
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-label.refund-card-title {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  color: var(--white_color);
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-label.refund-card-title h6 {
  color: var(--white_color);
  padding: 10px;
  margin: 0;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row {
  width: 100%;
  border: #ddd 1px solid;
  border-radius: 0 0 10px 10px;
  border-top: none;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row .receipt-card-content-label {
  margin: 0;
  border-top: #ddd 1px solid;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row .receipt-card-content-label .refund-column {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row .receipt-card-content-label .refund-column:last-child {
  align-items: center;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row .receipt-card-content-label .refund-column textarea {
  border-radius: 10px;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .receipt-card-content .receipt-card-content-row .receipt-card-content-label .refund-column>div {
  width: 100%;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-label.refund-card-title {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  color: var(--white_color);
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-label.refund-card-title h6 {
  color: var(--white_color);
  padding: 10px;
  margin: 0;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row {
  width: 100%;
  border: #ddd 1px solid;
  border-radius: 0 0 10px 10px;
  border-top: none;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label {
  margin: 0;
  border-top: #ddd 1px solid;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label .refund-column {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label .refund-column:last-child {
  align-items: center;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label .receipt-column:last-child {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label .refund-column textarea {
  border-radius: 10px;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .refund-card-content-row .refund-card-content-label .refund-column>div {
  width: 100%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading tbody tr td .ant-select-selection-item {
  white-space: inherit;
  width: 1px;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:first-child {
  width: 1%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(2) {
  width: 1% !important;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(3) {
  /* width: 10%; */
  width: 10%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(4) {
  /* width: 9%; */
  width: 15%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(5) {
  /* width: 9%; */
  width: 6%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(6) {
  /* width: 9%; */
  width: 6%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(7) {
  /* width: 21%; */
  width: 12%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(8) {
  /* width: 19%; */
  width: 9%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(9) {
  /* width: 9%; */
  width: 12%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(10) {
  /* width: 9%; */
  width: 7%;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(11) {
  width: 1% !important;
}

.bank-reconcile-list .bankreconcilelist-table>.ant-spin-nested-loading thead tr th:nth-child(12) {
  width: 1%;
}

.bank-reconcile-list .bankreconcilelist-table.inner-table .ant-table-thead {
  display: none;
}

button.btn-small.btn.btn-primary {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  font-size: 20px;
  color: var(--white_color);
  text-transform: none;
  padding: 0 25px;
  border-radius: 30px;
  height: 48px;
  line-height: 46px;
  margin-left: 5px;
  font-weight: 400;
  display: inline-block;
  min-width: inherit;
}

.refund-card-content-btn-small {
  margin-left: auto;
  margin-top: 10px;
}

.table-wrapper.trial-balance-table {
  /* width: 40%; */
  /* width: 45%; */
  margin: 0 auto;
}

.table-wrapper.trial-balance-table table tr:last-child {
  font-weight: bold;
}

.table-wrapper.trial-balance-table h5.trial-balance-heading {
  text-align: center;
  margin-top: 25px;
}

.table-wrapper.trial-balance-table h6.trial-balance-heading {
  text-align: center;
  margin-top: 25px;
}

.firm-summary.opening-balance-card {
  border: red 1px solid;
}

.firm-summary.opening-balance-card-matched {
  border: #129d00 1px solid;
}

.customer-name.opening-balance {
  width: 50%;
}

.firm-details .firm-summary .balance-block {
  /* margin-left: 500px; */
  width: 30%;
  display: flex;
  align-items: flex-start;
  grid-gap: 15px;
}

.firm-details .firm-summary.opening-balance {
  display: block;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 20px 34px #00000017;
  border-radius: 20px;
  margin-bottom: 20px;
  background: var(--white_color);
}

.firm-details .firm-summary .balance,
.firm-details .firm-summary .running-balance {
  margin: 0 auto;
  text-align: center;
}

.balance svg.svg-inline--fa.fa-pen-to-square.fa-w-16.fa-1x {
  margin-left: 15px;
  cursor: pointer;
}

#opening_balance_modal ::-webkit-scrollbar {
  width: 6px !important;
}

#opening_balance_modal .action-box {
  clear: both;
  display: flex;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-bottom: 0;
}

#opening_balance_modal .modal-body .action-box button {
  margin-right: 0 !important;
  right: 0;
  bottom: 0;
  margin-left: 15px;
}

#opening_balance_modal .action-box button {
  border-radius: 30px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table table {
  table-layout: fixed;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td,
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th {
  width: 48px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(2),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(2) {
  width: 55px !important;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(3),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(3) {
  width: 127px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(4),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(4) {
  width: 139px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(5),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(5) {
  width: 123px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(6),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(6) {
  width: 119px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(7),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(7) {
  width: 242px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(8),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(8) {
  width: 223px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(9),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(9) {
  width: 135px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(10),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(10) {
  width: 129px;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(11),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(11) {
  width: 120px !important;
}

.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-tbody tr td:nth-child(12),
.content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper.bankreconcilelist-table.inner-table .ant-table-thead tr th:nth-child(12) {
  width: 119px;
}

.modal.show #opening_balance_modal.modal-dialog {
  max-width: 450px;
}

span.reconcile-item-list-split {
  cursor: pointer;
}

.xpm-modal .ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #878787;
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  border-radius: 0 0 23px 23px;
  margin-top: 11px;
  color: #fff;
}

svg.svg-inline--fa.fa-minus.fa-w-14 {
  cursor: pointer;
}

svg.svg-inline--fa.fa-less-than.fa-w-12 {
  cursor: pointer;
}

.ant-table-content table .ant-table-tbody tr.ant-table-row {
  background: #fff;
  /* white-space: nowrap; */
}

.ant-form-item.half-width.beneficiary input.ant-input-disabled {
  background: #fff;
  color: var(--black__color) !important;
}

h6.col-md-2.center-align,
h6.col-md-1.center-align {
  text-align: center;
}

span.reconcile-item-list-split.disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

input.ant-input.record-payment {
  border-radius: 30px;
}

.refund-process .refund-process-box .container .refund-top-details input[type='text'].left-text-align.form-control {
  text-align: left;
}

.refund-top-details.record-payment {
  align-items: flex-end;
}

.refund-top-details.record-payment .ant-picker {
  border: #707070 1px solid;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  font-size: 14px;
  text-align: right;
  margin-top: 3px;
  background: #fff;
  border-radius: 30px;
}

.refund-top-details.record-payment .btn {
  float: right;
  font-size: 14px;
  padding: 5px 25px;
  min-width: inherit;
}

.refund-process .refund-process-box .refund-card-box.record-payment {
  padding: 0;
}

.client-account-balance {
  font-weight: 500;
}

.monthly-bank-reconciliation {
  padding: 15px;
  font-size: 16px;
}

.monthly-bank-reconciliation>.row:first-child>div {
  border-radius: 10px 10px 0 0;
}

.monthly-bank-reconciliation>.row:last-child>div:first-child {
  border-radius: 0 0 0 10px;
}

.monthly-bank-reconciliation>.row:last-child>div:last-child {
  border-radius: 0 0 10px 0;
}

.monthly-bank-reconciliation .center-align {
  text-align: center;
}

.monthly-bank-reconciliation .right-align {
  text-align: right;
}

.monthly-bank-reconciliation .left-align {
  text-align: left;
}

.monthly-bank-reconciliation .dark-row {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488b 100%) 0% 0% no-repeat padding-box;
  color: white;
  padding: 10px;
}

.monthly-bank-reconciliation .white-row {
  background: white;
}

.monthly-bank-reconciliation .gray-row {
  background: rgb(167 167 167);
}

.monthly-bank-reconciliation .light-theam-row {
  background: rgb(178 201 255);
}

.monthly-bank-reconciliation .border {
  border: 1px solid white;
  padding: 5px 10px;
}

.monthly-bank-reconciliation-btn {
  display: grid;
  margin-bottom: 10px;
}

.monthly-bank-reconciliation-btn .ant-picker {
  border: #707070 1px solid;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  font-size: 14px;
  text-align: right;
  margin-top: 3px;
  background: #fff;
  border-radius: 30px;
}

.ant-picker.receipt-modal {
  width: 100%;
  border-radius: 30px;
}

.receipt-details {
  width: 100%;
}

.fade.small-modal.receipt-details-modal.modal.show .modal-header {
  margin-bottom: 5px;
}

.fade.small-modal.receipt-details-modal.modal.show .modal-dialog.modal-100w.modal-dialog-centered {
  max-width: 1000px;
}

.full-content-block.monthly-bank-reconciliation {
  height: auto;
}

.general-ledger-report {
  box-sizing: border-box;
  border: 3px solid;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 10px;
  word-break: break-all;
  border-radius: 6px;
}

.general-ledger-report-top {
  box-sizing: border-box;
  border: 3px solid;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 10px;
  /* word-break: break-all; */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.general-ledger-table-top {
  margin-right: -15px;
  margin-left: -15px;
  box-sizing: content-box;
  /* border: 3px solid; */
  border-right: 3px solid;
  border-top: 3px solid;
  border-bottom: 1px solid;
  border-left: 3px solid;
  padding: 0px 13px 0px 13px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.topgap.btnarea {
  margin-top: 10px;
}

.topgap.btnarea .xero-sso-light>.xero-sso-link {
  background: #f4f4f4;
  border: none;
  color: #445569;
  font-weight: 700;
  border-radius: 30px !important;
  padding: 7px 25px 7px 15px;
  gap: 10px;
  box-shadow: none;
}

.topgap.btnarea .xero-sso-light>.xero-sso-link:hover {
  box-shadow: #00000030 0 1px 3px;
}

.topgap.btnarea .xero-sso-light>.xero-sso-link .xero-sso-logo {
  width: 2em;
  margin: 1px 0;
}

.ant-input-number-focused .ant-input-number-handler-wrap,
.ant-input-number:hover .ant-input-number-handler-wrap {
  margin-right: 7px;
}

.dashboard-tags {
  justify-content: flex-end;
  width: 100%;
}

.dashboard-tags .ant-tag {
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  cursor: auto;
}

.price-tabs .list-group-flush li:has(.condition) {
  margin-top: 25px;
  color: #001727;
}

.card-icons {
  position: relative;
  width: 100%;
}

.card-icons svg {
  width: 20px !important;
  position: absolute;
  left: 0;
  top: 17px;
  height: 20px;
  cursor: pointer;
}

.card-icons svg+svg {
  right: 0;
  left: auto;
}

.reconcile-list-table .ant-table-content table .ant-table-tbody tr.ant-table-row {
  cursor: pointer;
}

.atosearch .ant-table-content table .ant-table-tbody tr.ant-table-row {
  cursor: pointer;
}

span.reg-red {
  color: red;
}

/***** Firm Trust Tabs ****/
.firm-bank-account .full-content-block {
  overflow: auto;
}

body .firm-bank-account .full-content-block .firm-trust .ant-tabs .ant-tabs-content-holder {
  height: auto;
}

body .firm-bank-account .content-details {
  height: auto;
  overflow: auto !important;
}

@media (max-width: 1630px) {
  body .full-content-block .dashboard>h2 {
    text-align: left;
    padding-left: 230px;
    padding-top: 2px;
  }
}

@media (max-width: 1119px) {
  .dashboard .dashboard-pager {
    position: static;
    width: 100%;
    justify-content: flex-end;
    margin: 0px 5px 20px 0;
  }
}

@media (max-width: 1300px) {
  .dashboard .dashboard-pager {
    position: static;
    justify-content: flex-end;
    /* margin: -10px 5px 20px 0; */
  }

  body .full-content-block .dashboard h2 {
    text-align: center;
    padding: 3px 0 0;
  }
}

.ant-table-wrapper {
  margin-bottom: 10px;
}

.customer-table .ant-table-wrapper .ant-table-pagination.ant-pagination,
.content-details .ant-table-wrapper .ant-table-pagination.ant-pagination,
.cms-page .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-bottom: -35px !important;
}

.text-danger {
  font-size: 14px;
  font-weight: 500;
}

.content-details .table-top-btn {
  gap: 10px;
}

.table-top-btn .stripe-radio-btn {
  position: relative;
  cursor: pointer;
  gap: 10px;
  align-items: center !important;
}

/* .table-top-btn .stripe-radio-btn input[type='radio'] { */
/* width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer; */
/* } */

.table-top-btn .stripe-radio-btn input[type='radio']+label {
  border: #40a9ff 1px solid;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.table-top-btn .stripe-radio-btn input[type='radio']:checked+label {
  background: #40a9ff;
  color: #fff;
}

.stripiframebox,
.support-ticket-framebox {
  width: 100%;
  text-align: left;
}

.stripiframebox .StripeElement {
  border: 1px solid #d9d9d9;
  padding: 7px 10px;
  border-radius: 30px;
}

.stripiframebox .stripfield,
.support-ticket-framebox .support-ticket-field {
  text-align: left;
}

.stripiframebox .stripfield label,
.support-ticket-framebox .support-ticket-field label {
  font-weight: normal;
}

.stripiframebox .stripfield input,
.support-ticket-framebox .support-ticket-field input {
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  transition: all 0.3s;
}

.table-top-btn .stripe-radio-btn.list-group-item {
  padding: 0;
  border: none;
}

.table-top-btn .stripe-radio-btn.list-group-item .me-auto {
  padding: 10px;
  text-align: center;
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: #ccc 1px solid;
}

.table-top-btn .list-group .stripe-radio-btn.list-group-item:last-child .me-auto {
  border-bottom: none;
}

.table-top-btn .stripe-radio-btn.list-group-item.expired {
  pointer-events: none;
}

.table-top-btn .stripe-radio-btn.list-group-item.expired .me-auto {
  border-color: rgba(255, 0, 0, 0.1) !important;
  background: rgba(255, 0, 0, 0.1) !important;
  color: #00000070 !important;
}

.table-top-btn .stripe-radio-btn.list-group-item .me-auto strong {
  margin: 0 5px 0 auto;
}

.table-top-btn .stripe-radio-btn.list-group-item input[type='radio']:checked+.me-auto {
  background: #f0f0f0;
}

.content-details .table-top-btn.row {
  gap: 0;
  row-gap: 0;
}

.subscription-btn button {
  line-height: 1 !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
}

.subscription-btn button.reset.btn {
  border: rgb(0, 120, 200) 2px solid !important;
}

.subscription-plan.firm-setting {
  height: 100vh;
  background: var(--white_color);
}

.subscription-plan.firm-setting .custom-tabs h6::before {
  border: #005b98 1px solid;
}

.subscription-plan.firm-setting .custom-tabs h6 {
  /* background: var(--white_color) !important; */
  color: #005b98 !important;
}

.subscription-plan.firm-setting .custom-tabs h6.active {
  background: rgb(0, 91, 152) !important;
  color: white !important;
}

.apca-label {
  display: flex;
  align-items: center;
}

.ant-form-item-label:has(.apca-label) {
  overflow: visible !important;
}

.apca-info .info-icon .dropdown-toggle {
  background: none !important;
  color: #00000080 !important;
  padding: 0 0 0 10px !important;
  margin: 0;
  border: none !important;
}

.apca-info .info-icon .dropdown-toggle::after {
  font-family: 'FontAwesome';
  content: '\f05a';
  border: none;
  background: none;
}

.apca-info .info-icon .dropdown-menu {
  width: 340px;
  left: 0;
  background: #005b98;
  border: none;
}

.apca-info .info-icon .dropdown-menu::before {
  font-family: 'FontAwesome';
  content: '\f0d8';
  position: absolute;
  top: -24px;
  font-size: 26px;
  left: 15px;
  color: #005b98;
}

.apca-info .info-icon .dropdown-menu a {
  background: none !important;
  font-size: 14px;
  white-space: normal;
  letter-spacing: normal;
  font-weight: 500;
  color: var(--white_color);
}

.apca-info .info-icon .dropdown-menu a p {
  margin-bottom: 5px;
}

.apca-info .info-icon .dropdown-menu a p:last-child {
  margin-bottom: 0;
}

.modal-body .remove-content.confirmation-import {
  text-align: center;
}

.dashboard ul li .orphanATO-tfn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard ul li .orphanATO-card-footer {
  justify-content: space-between;
}

.dashboard ul li .orphanATO-card-footer span {
  margin-left: 10px;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view ul li .orphanATO-tfn {
  margin-top: 0px;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .ato-list-view li p {
  min-width: auto;
}

body .full-content-block .dashboard .ant-tabs-content-holder .list-view .ato-list-view li .orphanATO-card-footer {
  margin: 0px !important;
}

.form-box .manual_receipt_form .ant-form-item-label {
  text-align: left !important;
}

.form-box .manual_receipt_form .ant-col {
  padding: 0px;
}

.cms-page .ant-table-wrapper.atosearch .ant-table-pagination.ant-pagination {
  margin-bottom: 0 !important;
}

.date-picker-refresh {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
  padding: 0 20px;
}

.date-picker-refresh p {
  margin-bottom: 5px;
}

.date-picker-refresh h4 {
  text-align: center;
}

.header-not-imported {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-not-imported button {
  min-width: auto;
  padding: 8px 20px;
  font-size: 16px;
}

.oldest-sorting-body {
  display: inline-flex;
  gap: 15px;
  align-items: center;
  position: relative;
  z-index: 1;
}

.content-details .table-top-btn .oldest-sorting-body .ant-select {
  min-width: 200px !important;
}

.integration-card-info .info-icon .dropdown-toggle::after {
  font-family: 'FontAwesome';
  content: '\f05a';
  border: none;
  background: none;
  font-size: 18px;
}

.integration-card-info .info-icon .dropdown-toggle {
  background: none;
  color: #00000080;
  margin: 0;
  box-shadow: none;
}

.integration-bank-feed-card {
  position: relative;
}

.integration-card-info {
  position: absolute;
  top: 0;
  right: 0;
}

.integration-card-info .info-icon .dropdown-menu a {
  background: none !important;
  font-size: 14px;
  white-space: normal;
  letter-spacing: normal;
  font-weight: 500;
  color: var(--white_color);
}

.integration-card-info .info-icon .dropdown-menu {
  width: 550px;
  left: 0;
  background: #005b98;
  border: none;
}

/* .integration-card-info .info-icon .dropdown-menu::before {
  font-family: 'FontAwesome';
  content: '\f0d8';
  position: absolute;
  top: -24px;
  font-size: 26px;
  left: 15px;
  color: #005b98;
} */

.integration-card-info .info-icon .dropdown-menu p {
  text-align: left;
  margin-bottom: 1rem;
  font-size: 14px;
}

.bank-list-ul ul {
  column-count: 4;
}

@media (max-width: 992px) {
  .bank-list-ul ul {
    column-count: 2;
  }
}

.service-plan-card {
  display: flex;
  text-align: left;
  border: 1px solid #CCC;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  color: white;
  background: #0077c770;
}

.service-card-info.integration-card-info .info-icon .dropdown-toggle::after {
  font-family: 'FontAwesome';
  content: '\f05a';
  border: none;
  background: none;
  font-size: 18px;
  color: white;
}

.service-plan-card:has(input:checked) {
  background: var(--primary__color);
}

.service-plan-card input {
  accent-color: #0da500;
}

.service-price {
  font-weight: bold;
  font-size: 14px;
}

.strick-price {
  margin-right: 8px;
  text-decoration: line-through;
  font-size: 12px;
}

.integration-card-info .info-icon .dropdown-toggle:hover {
  color: #00000080;
  background: none;
  box-shadow: none;
}

.integration-card-info.service-card-info {
  position: relative;
}

.integration-card-info.service-card-info .info-icon .dropdown-toggle {
  padding: 0px;
}

.settings-page .ant-tabs-nav-list .ant-tabs-tab:first-child .ant-tabs-tab-btn,
.settings-page .ant-tabs-nav-list .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn,
.settings-page .ant-tabs-nav-list .ant-tabs-tab:nth-child(3) .ant-tabs-tab-btn {
  font-weight: 700;
}

.settings-page .ant-tabs-content-holder .ant-tabs-nav-list .ant-tabs-tab:first-child .ant-tabs-tab-btn,
.settings-page .ant-tabs-content-holder .ant-tabs-nav-list .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn,
.settings-page .ant-tabs-content-holder .ant-tabs-nav-list .ant-tabs-tab:nth-child(3) .ant-tabs-tab-btn {
  font-weight: inherit;
}

.plan-gst {
  font-size: 12px;
}

.xero-org {
  margin-top: -30px;
  margin-bottom: 15px !important;
}

.edit-ato-form .ant-form-item-control-input .ant-select {
  width: 100% !important;
}

.table-top-btn.refund-top-details.record-payment .file-note-input {
  border: #707070 1px solid;
  height: 30px;
  width: 90%;
  padding: 0 10px;
  font-size: 14px;
  text-align: right;
  margin-top: 0px;
  background: #FFF;
}

.ant-tooltip-inner {
  max-width: 230px;
  font-size: 12px;
}

.content-details.aba-tabs {
  padding: 10px !important;
}

.content-details.aba-tabs .firm-summary {
  padding: 10px !important;
  border-radius: 15px;
}

.content-details.aba-tabs .content-details.refund-process {
  padding: 10px;
}

.ant-tabs-content-holder:has(.content-details.refund-process) {
  padding: 0px !important;
}


body .full-content-block .ant-tabs .ant-tabs-content-holder .cms-page .content-details.refund-process .refund-process-box .container .table-btn {
  margin: 0px;
}

.wip-label {
  position: fixed !important;
  bottom: 0;
  background: #ffa456;
  color: #FFF;
  z-index: 3;
  left: 0;
  padding: 5px;
  width: 100%;
  text-align: center;
  /* font-weight: bold; */
  font-size: 20px;
}

.monthly-bank-reconciliation .green-row {
  color: #0da500 !important;
  border-radius: 0 0 10px 10px !important;
  background: #E0FBE2 !important;
  text-align: center;
}

/* Sidebar Css */
.sidebar-nav li {
  padding: 5px;
  padding-bottom: 0px;
}

.sidebar-nav li.active a,
.sidebar-nav li a:hover {
  background: #ffffff42 !important;
  width: 100%;
  margin: 0px;
  border-radius: 8px;
}

body .sidebar-nav ul li a {
  margin: 0px 0px;
  padding: 0px 11px;
  min-height: 50px;
}

[data-title]:after {
  left: calc(100% + 5px);
}

body header .header-nav li .dropdown-menu {
  padding: 0px;
  overflow: hidden;
}

body header .header-nav li .dropdown-menu a {
  padding: 5px 10px;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  background: #ffffff42 !important;
  color: white;
}

body header .header-nav li a:hover {
  opacity: 1;
}

body header .userprofile .dropdown-menu {
  padding: 0px;
}


.monthly-amount-edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
}

.monthly-amount-edit .ant-form-horizontal {
  width: fit-content;
}

.monthly-amount-edit .ant-form-item {
  max-width: 150px;
  width: fit-content;
  margin: 0px !important;
}

.monthly-amount-edit .amount-change-button button {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box;
  /* font-size: 20px; */
  font-size: 16px;
  color: var(--white_color);
  text-transform: none;
  /* padding: 0 25px; */
  padding: 5px;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  line-height: 1px;
  font-weight: 400;
  display: inline-block;
  min-width: inherit;
}

.monthly-amount-edit .amount-change-button button:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;
}

.smallest-modal .modal-dialog {
  width: 358px;
}

#monthlyBalanceForm {
  padding: 15px;
  padding-top: 0px;
  margin-top: -15px;
}

#monthlyBalanceForm.ant-form-vertical .ant-form-item {
  width: 100%;
  padding: 0px;
}

#monthlyBalanceForm.ant-form-vertical .ant-form-item .ant-form-item-control {
  padding: 0px !important;
}

.smallest-modal .action-box button {
  min-width: 90px !important;
  font-size: 16px !important;
  min-height: auto !important;
  padding: 0 !important;
  text-transform: none !important;
}

.addPaymentReceipt-modal .modal-dialog {
  max-width: 95vw !important;
  z-index: 80000;
}

.addPaymentReceipt-modal {
  z-index: 2000 !important;
}

.ant-modal-root .ant-modal-wrap:has(.confirmModal) {
  z-index: 2500 !important;
}

.xero-btn {
  background: #f4f4f4;
  border: none;
  border-radius: 30px !important;
  box-shadow: none;
  color: #445569;
  font-weight: 700;
  /* gap: 10px;
  padding: 7px 15px 7px 15px !important; */
  gap: 7px;
  padding: 0px 5px !important;
  font-size: 15px !important;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
}

.xero-btn>* {
  box-sizing: border-box;
}

.xero-btn>.xero-logo {
  margin: 1px 0;
  width: 2em;
}

.xero-logo {
  border-radius: 50%;
  width: 2em;
  margin: .25em 9px .25em .25em;
  vertical-align: middle;
}

.content-details .table-top-btn .table-btn.monthly-bank-reconciliation-btn a:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box !important;
}

.refund-process .refund-process-box.matched .refund-card-box .matching-btn {
  background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box !important;
  color: white;
}

.refund-process .refund-process-box.matched .refund-card-box .matching-btn:hover {
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box !important;
  color: white;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.rightalign.invoice_number_input {
  align-items: center;
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.rightalign.invoice_number_input input {
  width: 22%;
  border-radius: 15px;
  border: 1px solid #707070;
  height: 30px;
  padding: 0px 10px;
  font-size: 14px;
  margin-top: 3px;
  background: #FFF;
}

.yodlee-bank-account-list {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 10px;
}

.yodlee-bank-account-list ul {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.yodlee-bank-account-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
}

body .sidebar-nav.open .sidebtn:before {
  content: '\f100';
}

.record-payment .error-payment-amount {
  border-color: red !important;
}

/* Inline Css Style to External Css */
.idle-expire-modal .idle-expire-header {
  margin-bottom: 10px !important;
}

.idle-expire-modal .idle-expire-body {
  margin: 0 10px !important;
}

.dialog-modal .dropdown-item.information {
  width: 100% !important;
}

.aba-approve-mail-modal .aba-approve-mail-body {
  padding-bottom: 0px !important;
}

.aba-approve-mail-modal .aba-approve-mail-action {
  margin-bottom: 30px !important;
}

.aba-page .aba-record-count {
  padding: 0px 12px !important;
  font-size: 16px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.aba-page .aba-table {
  width: 100% !important;
  height: 85% !important;
}

.spin-body-relative {
  position: relative !important;
}

.modal-spin-center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 3 !important;
}

.top-modal-spin-center {
  position: absolute !important;
  top: 25% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 3 !important;
}

.aba-transaction-page .aba-transaction-table {
  width: 100% !important;
  height: 85% !important;
  margin-top: 15px !important;
}

.opening-balance-body {
  flex-flow: wrap !important;
}

.opening-balance h4 {
  margin-bottom: 0px !important;
}

.admin-registered-expand-table {
  width: 100% !important;
  height: 85% !important;
}

.admin-registered-invoice-action {
  margin: 10px !important;
}

.admin-registered-invoice-modal {
  width: 100% !important;
  margin: 0px !important;
}

.disconnect-service-body {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.resend-password-action {
  align-items: center !important;
}

.sales-force-status-body {
  align-items: center !important;
  justify-content: center !important;
}

.sales-force-status-xero-btn {
  min-height: 40px !important;
  /* margin-right: 10px !important; */
  min-width: 60px !important;
  border: 1px solid #bdb9b9 !important;
}

.ato-refund-react-table {
  width: 100% !important;
  height: 88% !important;
}

.edit-amount-modal .edit-amount-action {
  margin-top: 10px !important;
}

.add-new-contact-modal .add-new-contact-cancel {
  border-radius: 30px !important;
}

.audit-statement-react-table {
  width: 100% !important;
  height: 85% !important;
}

.trust-firm-setting-apaca.apca-info .info-icon .dropdown-toggle {
  height: 22px !important;
  display: flex !important;
  align-items: center !important;
}

.trust-firm-setting-apaca.apca-info .info-icon .dropdown-toggle::after {
  font-size: 13px !important;
}

.trust-firm-setting-apaca.apca-info .info-icon .dropdown-menu {
  width: 180px !important;
  top: 10px !important;
}

.content-details .entry-card .form-box .address-box.contact-firm-box .ant-form-item,
.content-details .entry-card .form-box .address-box.trust-firm-box .ant-form-item,
.content-details .entry-card .form-box .address-box.subscription-firm-box .ant-form-item {
  width: calc(25% - 88.25px) !important;
  padding: 0 !important;
}

.content-details .entry-card .form-box .address-box.subscription-firm-box .subscription-firm-title {
  display: flex !important;
  align-items: center !important;
}

.content-details .entry-card .form-box .address-box.subscription-firm-box .subscription-firm-status {
  margin-left: auto !important;
  font-size: 16px !important;
}

.content-details .entry-card .form-box .address-box.subscription-firm-box .subscription-firm-status-text {
  text-transform: capitalize !important;
  background: #0078C8 !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  text-shadow: #000 1px 1px 1px !important;
  color: #FFF !important;
  padding: 5px 10px !important;
  font-weight: normal !important;
  font-style: normal !important;
}

.firm-setting-form {
  padding: 0 0 30px !important;
}

.disconnect-bank-modal {
  text-align: center !important;
  padding-top: 15px !important;
}

.current-subscription-box .no-sub-body {
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}

.current-subscription-box .no-sub-body .sub-now {
  background: #0078c8 !important;
  border: none !important;
  color: white !important;
  min-width: fit-content !important;
  padding: 0px 15px !important;
  font-size: 16px !important;
}

.current-subscription-box .sub-detail-body {
  display: flex !important;
  align-items: center !important;
}

.current-subscription-box .sub-detail-body .sub-status {
  margin-left: auto !important;
  font-size: 16px !important;
}

.current-subscription-box .sub-detail-body .sub-status .sub-status-text {
  text-transform: capitalize !important;
  background: #0078C8 !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  text-shadow: #000 1px 1px 1px !important;
  color: #FFF !important;
  padding: 5px 10px !important;
  font-weight: normal !important;
  font-style: normal !important;
}

.current-subscription-box .change-plan-btn {
  background: #0078c8 !important;
  color: white !important;
}

.cancel-sub-modal .cancel-sub-header {
  margin-bottom: 20px !important;
}

.cancel-sub-modal .cancel-sub-body {
  text-align: center !important;
  padding-bottom: 20px !important;
}

.audit-statement .audit-delete-count {
  width: fit-content;
  min-width: fit-content;
  margin-right: 15px;
  padding: 0 20px;
  border-radius: 30px;
  height: 35px;
  line-height: 35px;
  margin-left: 5px;
  font-weight: 400;
  display: inline-block;
}

.bank-transaction-delete-modal .bank-transaction-delete-body {
  text-align: center;
}

.bank-transaction-delete-modal .bank-transaction-delete-action {
  padding-bottom: 30px;
}

.monthly-expand-item .monthly-expand-item-icon {
  cursor: pointer;
}

.monthly-expand-item .monthly-expand-item-text {
  margin-left: 10px;
}

.statement-page-react-table {
  width: 100% !important;
  height: 85% !important;
}

.import-file-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.beneficiaries-page .beneficiaries-table-top {
  align-items: flex-start;
}

.beneficiaries-page .beneficiaries-or-text {
  justify-content: center;
  margin-left: 4px;
}

.beneficiaries-page .content-details .search-section.process-search {
  margin-bottom: 0px;
}

.beneficiaries-page .beneficiaries-add-client {
  padding: 0px 12px !important;
  font-size: 13px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.beneficiaries-cancel-btn {
  border-radius: 30px;
}

.subscription-detail-content .subscription-detail-content-body {
  background: white;
}

.subscription-detail-content .subscription-detail-content-per-text {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.7;
  margin: 0;
}

.subscription-detail-content .support-plan-title {
  margin: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}

.suppliers-page .suppliers-table-top {
  align-items: flex-start;
}

.suppliers-page .suppliers-or-text {
  justify-content: center;
  margin-left: 4px;
}

.suppliers-page .suppliers-add-client {
  padding: 0px 12px !important;
  font-size: 13px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.suppliers-cancel-btn {
  border-radius: 30px;
}

.chart-of-account-page .chart-of-account-new-btn {
  margin-bottom: 0px !important;
}

@media (min-width: 1600px) {
  .chart-of-account-page .chart-of-account-new-btn {
    margin-bottom: 60px !important;
  }
}

.archived-client .archived-client-delete-btn {
  width: fit-content;
  min-width: fit-content;
  margin-right: 15px;
  padding: 0 20px;
  border-radius: 30px;
  height: 35px;
  line-height: 35px;
  margin-left: 5px;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
}

.dashboard .dashboard-grid-list-view {
  position: relative;
}

.dashboard-tags .ant-tag.last-tag {
  margin-right: 110px;
}

.dashboard .dashboard-header-wrapper .process-refund-btn {
  width: auto;
  /* position: absolute; */
  /* top: 30px; */
  /* left: 30px; */
  font-weight: 500;
  color: #000;
}

.dashboard .dashboard-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard .dashboard-pager .sort-by {
  margin-right: 20px;
}

/* Support Ticket Css */

.support-ticket {
  padding: 0 15px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.support-ticket .editor-class.rdw-editor-main {
  margin-bottom: 0px;
}

.support-ticket.support-ticket-framebox .support-ticket-field label {
  margin-bottom: 5px;
}

body .modal-dialog .modal-body .support-ticket .upload-div {
  margin: 0px;
  margin-bottom: 20px;
}

body .modal-dialog .modal-body .support-ticket .upload-div .ant-upload.ant-upload-drag .ant-upload {
  padding: 10px 0;
}

body .modal-dialog .modal-body .support-ticket .upload-div .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 10px;
  font-size: 12px !important;
}

body .modal-dialog .modal-body .support-ticket .upload-div .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
}

body .modal-dialog .modal-body .support-ticket .label-required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

body .modal-dialog.fullWidthModal {
  max-width: 95vw;
}

body .modal-dialog.fullWidthModal .modal-content {
  max-height: 95vh;
  overflow-y: auto;
}

.support-ticket-view .support-ticket-header {
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: fit-content;
  margin-top: -15px;
  font-size: 16px;
  font-weight: 600;
}

.support-ticket-view {
  padding: 0 15px 15px 15px;
}

.support-ticket-view .support-ticket-header .ticket-subject-body {
  display: flex;
  align-items: center;
  gap: 5px;
}

.support-ticket-view .ticket-card {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.support-ticket-view .support-ticket-details,
.support-ticket-view .support-ticket-thread {
  margin-top: 15px;
}

.support-ticket-view .support-ticket-thread .reply-btn {
  width: fit-content;
  min-width: fit-content;
  /* margin-right: 15px; */
  padding: 0 15px;
  border-radius: 30px;
  height: 35px !important;
  min-height: 35px !important;
  margin-right: 5px;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.support-ticket-view .status-btn {
  width: fit-content;
  min-width: fit-content;
  /* margin-right: 15px; */
  padding: 0 15px;
  border-radius: 30px;
  height: 30px !important;
  min-height: 30px !important;
  margin-right: 5px;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.support-ticket-view .support-ticket-details .status-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.support-ticket-view .support-ticket-details .ticket-card-title {
  font-weight: 600;
  color: var(--lightblue_color);
  font-size: 16px;
}

.support-ticket-view .support-ticket-details .ticket-card-message {
  font-size: 14px;
  color: #000;
}

.support-ticket-view .support-ticket-thread .support-ticket {
  padding: 0px;
}

.support-ticket-view .support-ticket-thread .reply-input-box {
  margin-top: 10px;
}

.support-ticket-view .support-ticket-thread .reply-list {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.support-ticket-view .support-ticket-thread .reply-list .reply-header {
  /* padding: 5px 10px;
  border-radius: 10px;
  background: var(--primary__color);
  font-weight: 700;
  margin-bottom: 15px;
  width: fit-content;
  color: white; */
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.support-ticket-view .support-ticket-thread .reply-list .reply-header .font-bold {
  font-weight: 700;
}

.support-ticket-view .support-ticket-thread .reply-list .primary-card {
  border-color: var(--primary__color);
}

.support-ticket-view .support-ticket-thread .reply-list .primary-card:hover {
  background-color: #f0f9ff;
}

.support-ticket-view .support-ticket-thread .reply-list .primary-card .font-bold {
  /* color: var(--primary__color); */
  font-weight: 700;
}

.support-ticket-view .support-ticket-thread .reply-list .support-comment-card {
  border-color: #000;
}

.support-ticket-view .support-ticket-thread .reply-list .support-comment-card:hover {
  background-color: #f0f9ff;
}

.support-ticket-view .support-ticket-thread .reply-list .support-comment-card .font-bold {
  color: #000;
  font-weight: 700;
}

.support-ticket-view .attachment-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary__color);
  /* margin-top: 10px; */
  margin-top: -20px;
}

.support-ticket-view .attachment-text:hover {
  cursor: pointer;
}

.support-ticket-view .reply-btn.outline {
  border: var(--lightgrey_color) 2px solid;
  color: var(--black__color);
  background: none;
  padding: 0 13px;
}

body .modal-dialog .modal-body .support-ticket-view .upload-btn-div button {
  font-size: 16px !important;
}

.view-more-btn {
  color: var(--primary__color);
}

.admin-list-table {
  margin-left: 0px !important;
  height: auto !important;
}

.admin-list-table .content-details {
  padding: 30px;
}

.admin-modal .upload-btn-div {
  justify-content: end;
  width: 100%;
}

.status-switch {
  /* min-width: 85px !important; */
  min-height: 26px;
}

.status-switch .ant-switch-handle {
  top: 4px;
  left: 3px;
  width: 18px;
  height: 18px;
}

.status-switch-body .status-switch.ant-switch-checked {
  background-color: rgba(15, 91, 0, 1);
}

.status-switch-body .status-switch {
  background-color: rgba(255, 0, 0, 0.8);
}

.status-switch.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 21px);
}

.status-switch-body {
  width: 115px;
}

.status-switch-body .ant-spin-spinning {
  width: fit-content;
  margin-right: 10px;
}

.status-switch-body {
  position: relative;
}

.status-switch-body .spin-status {
  position: absolute;
  top: 0px;
  left: -30px;
}

.partial-registed-firm-page .search-section,
.registed-firm-page .search-section {
  margin-bottom: 20px !important;
}

.partial-registed-firm-page .search-section,
.registed-firm-page .search-section .summary-filter {
  margin: 0px !important;
}

.support-view-spin {
  padding: 0;
  margin-bottom: 30px;
}

.dashboard-status-item {
  display: flex;
  align-items: center;
  gap: 5px;
  /* padding-top: 10px; */
}

.dashboard-status-item .support-status-body {
  min-width: auto;
  width: auto;
  text-align: left;
}

.support-status-body {
  width: 100%;
  text-align: center;
  min-width: 100px;
}

.support-ticket-view .support-status-body {
  min-width: 100%;
  text-align: left;
}

.support-status-body .support-status-text {
  margin-left: 5px;
}

.support-status-new {
  color: var(--primary__color);
}

.support-status-open {
  color: #f54c40;
}

.support-status-in-progress {
  color: #e6b12e;
}

.support-status-resolved {
  color: green;
}

.support-status-closed {
  color: grey;
}

img.small-favicon {
  width: 16px;
}

.support-ticket-view .support-ticket-thread .attachment-btn {
  padding: 0px;
  height: fit-content !important;
  background: transparent;
  color: var(--primary__color);
  font-weight: 500;
}

.support-ticket-view .support-ticket-thread .replied-by {
  margin-top: 20px;
}

.support-ticket-view .support-ticket-thread .ticket-card p {
  margin: 0px;
}

.support-ticket-view .reply-sync-btn {
  margin-left: 10px;
}

.support-ticket-view .reply-sync-btn:hover {
  cursor: pointer;
  color: var(--primary__color);
}

.dashboard-status-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 5px;
}

@media (max-width: 1260px) {
  .dashboard-status-body {
    grid-template-columns: 1fr;
  }
}

.upload-div button.attachment-btn {
  padding: 0px;
  height: fit-content !important;
  background: transparent;
  color: var(--primary__color);
  font-weight: 500;
}

.upload-div button.reply-btn {
  width: fit-content;
  min-width: fit-content;
  padding: 0px;
  border-radius: 30px;
  height: 35px !important;
  min-height: 35px !important;
  margin-right: 5px;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

button.attachment-btn:active {
  background: transparent !important;
  color: var(--primary__color) !important;
}

.reconcile-desc {
  align-items: baseline !important;
  gap: 10px !important;
  margin: 0px !important;
  text-align: left !important;
}

.fetch-fontawesome-icon-btn {
  padding: 0px !important;
  margin: 0px !important;
  background: transparent !important;
  color: #000 !important;
  width: auto !important;
  min-width: auto !important;
  height: auto !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.fetch-fontawesome-icon-btn.disabled,
.fetch-fontawesome-icon-btn.disabled svg {
  cursor: not-allowed !important;
}

body .full-content-block:has(.dashboard) {
  height: calc(100vh - 130px);
}

body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs .ant-tabs-content-holder:has(.subscription-plan.firm-setting) {
  overflow: unset;
  height: 100%;
}

.multiple-selection-action-button {
  min-width: fit-content !important;
  font-size: 14px !important;
  padding: 2px 10px !important;
}

.content-details .table-top-btn .search-section.client-multiple-selection .ant-select {
  min-width: 280px !important;
}

.dashboard .list-view .ant-empty-normal {
  padding: 32px 0;
  margin: 0px;
}

.current-subscription-box p label {
  font-weight: 500;
}

.refund-process .refund-process-box .container .table-top-btn .btn.ok-btn:not([disabled]) {
  background: var(--primary__color);
}

.refund-process .refund-process-box .container .table-top-btn .btn.ok-btn:not([disabled]):hover {
  background: #012F4E;
}

.dialog-modal .dropdown-item.information p {
  margin-bottom: 15px;
}

.dialog-modal .dropdown-item.information p:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: all ease-in-out;
}

.dialog-modal .dropdown-item.information p:last-child {
  margin-bottom: 0px;
}

.help-text-inner-body p {
  white-space: pre-wrap;
  font-size: 16px;
}

.help-text-inner-body span {
  font-weight: bold;
  margin-right: 5px;
}

.help-text-inner-body img {
  width: 80%;
  height: auto;
  margin-bottom: 15px;
}

.help-text-inner-body ol ul {
  list-style-type: disc;
}

.help-text-inner-body ol li::marker {
  font-weight: bold;
}

.activity-content .ant-table-pagination.ant-pagination {
  margin-bottom: -35px;
}

.faq-page .content-details {
  padding-top: 30px;
}

.faq-page .ant-collapse-expand-icon {
  display: flex;
}

.faq-page .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  align-items: center;
}

.abstract-image-body {
  width: 100%;
  height: 75px;
  overflow: hidden;
}

.abstract-image-body img {
  width: 100%;
  height: 147%;
  object-fit: cover;
}

.card-input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .85);
  display: inline-block;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  min-width: 0;
  padding: 5px 11px;
  position: relative;
  transition: all .3s;
  width: 100%;
  border-radius: 6px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card-container {
  width: 320px;
  height: 165px;
  position: relative;
  perspective: 1000px;
  /* Enables 3D effect */
}

.card-wrapper {
  width: 95%;
  height: 85%;
  position: absolute;
  transform-style: preserve-3d;
  /* Ensures both sides flip properly */
  transition: transform 0.6s ease-in-out;
}

.card-container.flipped .card-wrapper {
  transform: rotateY(180deg);
}

.credit-card2 {
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
  padding: 20px;
  color: white;
  font-family: "Arial", sans-serif;
  position: absolute;
  backface-visibility: hidden;
  /* Hides back when front is visible */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
  background: #ffffff;
  color: black;
}

.card-chip {
  width: 40px;
  height: 30px;
  background: gold;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.card-number {
  font-size: 22px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 50px;
}

.card-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
}

.edit-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.card-input {
  width: 100%;
  margin: 10px 0;
}

.card-container button {
  margin: 5px;
}

.card-container .card-name {
  background: var(--primary__color);
  width: fit-content;
  color: white;
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 15px;
}

.active-check-body {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.active-check-body label {
  margin: 0px;
  font-weight: normal;
  cursor: pointer;
}

.active-check-body input[type="checkbox"] {
  margin: 0px;
}

.card-container .card-switch {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-container .switch-input {
  display: none;
}

.card-container .switch-label {
  width: 50px;
  height: 25px;
  background: #ccc;
  border-radius: 15px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: background 0.3s;
}

.card-container .switch-label::after {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}

.card-container .switch-input:checked+.switch-label {
  background: #06c76e;
}

.card-container .switch-input:checked+.switch-label::after {
  left: 26px;
}

.card-wrapper.default {
  border: 4px solid #06c76e;
  border-radius: 21px;
}

.card-wrapper.expired {
  border: 4px solid red;
  border-radius: 21px;
}

.card-wrapper.expired.defaultE::before {
  content: "Default";
  top: -31px;
  position: absolute;
  right: 13px;
  background: red;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
}

.card-wrapper.expiringSoon {
  border: 4px solid #FFCC00;
  border-radius: 21px;
}

.card-wrapper.expiringSoon.defaultES::before {
  content: "Default";
  top: -31px;
  position: absolute;
  right: 13px;
  background: #FFCC00;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
}

.card-wrapper.default::before {
  content: "Default";
  top: -31px;
  position: absolute;
  right: 13px;
  background: #06c76e;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
}

body .modal-dialog.small-popup.sub-card-modal .modal-body {
  padding: 0px;
}

.search-clear-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}

.search-clear-icon:hover {
  cursor: pointer;
}

.opening-balance-date {
  margin: 0px;
  color: var(--primary__color);
  font-size: 16px;
}