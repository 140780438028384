:global .refund-process .table-top-btn h2{
    font-size: 20px;
    color: #000;
    font-weight: 500;
}
:global .refund-process .form-check{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
:global .refund-process .form-check input[type=checkbox]{
    margin-right: 10px;
    width: 20px;
    height: 20px;
    margin-top: 0;
}
:global .refund-process .form-check label{
    font-size: 18px;
}
:global .refund-process .form-box{
    padding-bottom: 10px;
}
:global .refund-process .refund-process-box{
    background: rgba(191, 230, 255, 0.5);
    border-radius: 30px;
    /* padding: 30px 20px; */
    padding: 10px 15px;
    margin-bottom: 20px;
    border: rgba(191, 230, 255, 0.5) 1px solid;
}
:global .refund-process .refund-process-box.matched{
    border-color: #0DA500;
}
:global .refund-process .refund-process-box.red{
    border-color: #F00;
}
:global .refund-process .refund-process-box .container{
    padding: 0;
    margin: 0;
    max-width: 1475px;
}
:global .refund-process .refund-process-box .container .table-top-btn{
    background: #EEE;
    /* margin-top: 20px; */
    margin-top: 10px;
    padding: 10px;
}
:global .refund-process .refund-process-box .container .table-top-btn input[type=text]{
    border: none;
    min-height: 34px;
    min-width: 700px;
    padding: 0 10px;
    background: #FFF url(../../assets/images/search-black.png) no-repeat 98% center;
}
:global .refund-process .refund-process-box .container .table-top-btn .btn{
    background: #777;
    min-width: 90px;
    height: 34px;
    line-height: 1;
    padding: 0;
    font-size: 16px;
}
:global .refund-process .refund-process-box .container .table-top-btn .btn:hover{
    background: #012F4E;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper{
    margin: 0;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table{
    border-radius: 0;
    border: none;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table thead th{
    background: #FFF;
    padding: 10px 20px;
    width: auto;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table thead tr th:nth-child(4){
    width: 30%;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table thead tr th:nth-child(5), :global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table tbody tr td:nth-child(5){
    text-align: right;
}
:global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table thead tr th:first-child, :global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table thead tr th:last-child, :global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table tbody tr:last-child td:first-child, :global .refund-process .refund-process-box .container .ant-table-wrapper .ant-table-content table tbody tr:last-child td:last-child{
    border-radius: 0 !important;
}
:global .refund-process .refund-process-box .container .refund-top-details{
    margin-bottom: 60px;
}
:global .refund-process .refund-process-box .container .refund-card-box .refund-top-details{
    margin: 0;
}
:global .refund-process .refund-process-box .container .refund-card-box .refund-top-details label{
    margin-top: 15px;
    font-weight: 700;
}
:global .refund-process .refund-process-box .container .refund-card-box .refund-top-details input[type=text]{
    width: 100%;
    text-align: left;
    height: 40px;
    border-radius: 20px;
}
:global .refund-process .refund-process-box .container .refund-top-details input[type=text]{
    border: #707070 1px solid;
    height: 30px;
    width: 90%;
    padding: 0 10px;
    font-size: 14px;
    text-align: right;
    margin-top: 3px;
    background: #FFF;
}
:global .refund-process .refund-process-box .container .refund-top-details .col-6{
    width: auto;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: 17.5%;
}
:global .refund-process .refund-process-box .container .refund-top-details .col-6.price-box{
    align-items: flex-end;
    font-weight: 700;
    /* padding-right: 6%; */
}
:global .refund-process .refund-process-box .container .refund-top-details .col-6:first-child{
    width: 5%;
    margin-top: 4px;
}
:global .refund-process .refund-process-box .container .refund-top-details .col-6:nth-child(6){
    width: 25%;
    align-items: flex-end;
    padding-left: 4%;
}
:global .refund-process .refund-process-box .container .refund-top-details .col-6 strong{
    font-weight: 500;
}
:global .refund-process .refund-process-box .refund-card-box{
    padding-left: 5%;
    margin-top: -25px;
}
/* :global .refund-process .refund-process-box .refund-card-box .col-6{
    width: auto;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card{
    width: 587px;
} */
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .table-btn{
    margin-top: -10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 20px;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .table-btn button{
    min-width: inherit;
    font-size: 16px;
    text-transform: capitalize;
    padding: 10px 20px;
    line-height: 1;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .table-btn .btn-link{
    background: none;
    color: #000;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .table-btn .btn-link:hover{
    color: #0077C7;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card{
    position: relative;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card label{
    width: 100%;
    /* padding: 10px 25px; */
    padding: 7px 25px;
    text-align: center;
    position: relative;
    color: #000;
    /* font-size: 20px; */
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    background: #EEE;
    border-radius: 15px 15px 0 0;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown{
    position: absolute;
    right: 0;
    top: 0;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-toggle{
    min-width: inherit;
    padding: 0;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-toggle:after{
    display: none;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-menu{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 20px #00000038;
    border: 2px solid #99B5C7;
    border-radius: 10px;
    padding: 0;
    width: 140px;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-menu .dropdown-item{
    padding: 7px 10px;
    font-size: 14px;
    line-height: 1;
    border-bottom: #99B5C7 2px solid;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-menu .dropdown-item:last-child{
    border: none;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card > .dropdown .dropdown-menu .dropdown-item:hover{
    color: #0077C7;
    background: none;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .fa-ellipsis-v{
    cursor: pointer;
    position: absolute;
    right: 20px;
    font-size: 18px;
    color: #0077C7;
    z-index: 1;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .right-block{
    margin-left: auto;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content{
    /* padding: 25px; */
    padding: 10px;
    display: flex;
    flex-flow: wrap;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p{
    width: 100%;
    margin-bottom: 7px;
    /* font-size: 18px; */
    font-size: 15px;
    font-weight: normal;
    color: #000;
    display: flex;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p strong{
    font-weight: 500;
    color: #0077C7;
    /* min-width: 55px; */
    padding-right: 10px;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p b span{
    padding-left: 10px;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.remittance{
    color: #0077C7;
    font-weight: 600;
    /* margin-top: 20px; */
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.remittance strong{font-weight: 600;}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.name{
    flex-direction: column;
}
:global .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p.rightalign{
    justify-content: flex-end;
}
:global .refund-process .refund-process-box .refund-card-box .matching-btn-section{
    /* min-width: 203px; */
    text-align: center;
    padding: 0px;
    padding-top: 50px;
}
:global .refund-process .refund-process-box .refund-card-box .matching-btn{
    background: #D4D4D4;
    border: none;
    /* font-size: 16px; */
    font-size: 15px;
    text-transform: none;
    color: #000;
    font-weight: 500;
    line-height: 1;
    /* min-width: 100px; */
    min-width: 90px;
    /* min-height: 39px; */
    min-height: 30px;
    padding: 0;
}
:global .refund-process .refund-process-box .refund-card-box .matching-btn:hover{
    background: #012F4E;
    color: #FFF;
}
:global .refund-process .refund-process-box .refund-card-box .refund-card{
    background: #FFF;
    border: #EEE 1px solid;
    /* border-radius: 30px; */
    border-radius: 15px;
}
