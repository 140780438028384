:global .ant-form-vertical .ant-form-item {
  width: 25%;
}

:global .ant-form-item-label {
  flex-grow: 1;
}

:global .content-details .ant-table-content table{
	border: #F1F1F1 1px solid;
	border-radius: 20px;
	overflow: hidden;
}
:global .content-details .ant-table-content table thead th{
  background: #F1F1F1;
  color: #0077C7;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 20px;
  /* width: 20%; */
  border-right: #ECECEC 1px solid;
}
:global .content-details .ant-table-content table thead th:last-child, :global .content-details .ant-table-content table tbody tr td:last-child{
	text-align: center;
  white-space: nowrap;
}
:global .content-details .ant-table-content table tbody td{
	padding: 11px 20px;
	border-right: #ECECEC 1px solid;
  border-bottom-color: #ECECEC;
  word-break: break-word;
}
:global .content-details .ant-table-content table thead tr th:first-child{
	border-top-left-radius: 20px !important;
}
:global .content-details .ant-table-content table thead tr th:last-child{
	border-top-right-radius: 20px !important;
}
:global .content-details .ant-table-content table tbody tr td:last-child svg{
  cursor: pointer;
}
:global .content-details .ant-table-content table tbody tr:last-child td:last-child{
	border-bottom-right-radius: 20px;
}
:global .content-details .ant-table-content table tbody tr:last-child td:first-child{
	border-bottom-left-radius: 20px;
}
:global .content-details .user-list .ant-table-content table .ant-table-tbody tr td:first-child::first-letter{
	background: rgba(0, 69, 116, 0.4);
    font-size: 14px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    color: #FFF;
    text-transform: uppercase;
    display: block;
    padding: 0 5px;
    margin-right: 8px;
}
:global .ant-table-cell a{
  color: #000;
}
:global .ant-table-pagination.ant-pagination{
  margin: 30px 0 0;
}
:global .ant-table-wrapper{
  margin-top: 22px;
}
:global .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  display: none;
}
:global .ant-table-pagination.ant-pagination a{
  border: none;
  padding: 0;
  font-size: 16px;
}
:global .ant-pagination-item-active{
  border: none;
  color: #000;
}
:global .ant-table-pagination.ant-pagination a{
  color: #000;
  opacity: 0.4;
  line-height: 2;
}
:global .ant-table-pagination.ant-pagination .ant-pagination-item-active a, :global .ant-table-pagination .ant-pagination:hover a{
  color: #000;
  opacity: 1;
}
:global .ant-pagination-prev .ant-pagination-item-link, :global .ant-pagination-next .ant-pagination-item-link{
  border: none;
  line-height: 1;
  font-size: 16px;
}
:global .ant-pagination-item{
  border: none;

}
:global .search-section .ant-input{
  width: 464px;
  height: 33px;
  border: 1px solid #E2E2E2;
  border-radius: 30px;
  color: #000;
  font-size: 12px;
  padding: 0 20px;
  margin-right: auto;
}
:global .search-section.user-search .ant-input, :global .aba-tabs .search-section .ant-input{
  background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAABHNCSVQICAgIfAhkiAAAAXFJREFUOE+lUz1LxEAUnNX6asFetNBSi4ug2Ahi4UdnY6FgI1h4FxuttIp3iNYKFrZ+gCDYiAdZCwUrEfUXmFbru3U2u0n2zEU4DGwg783Mm/f2ReAfj8hxK3IKPRjO4uoWwfh7pxoZWZMEDnkcoqUoNCDU2m8RQ/Yf5gB1maorvBD8AQiPsT7HxZArIOCH/QQ9W1CEFpZQ8+5Sgh8uM38af2vRfW8kyQlU5Tat7saBJsZQ955y/bkCEPMIylcao8n3fE9Q9oKWFguH78tP406dELdqyL5U1tIOLe0Vk8NzwhdovUHcZEI2igp1BiuF5A4O9cCOqbhCUkRLA7T03aHnQWLebHwDgXdkKm/KUfTi0SSyfpxpl6DEtZlLjEmvy95zWl1ntYMtNMUrN22apHUzqPhpc5eQS0wcWPuFbdvB6gUq6/badzvetNasIxJxkGc8N3Qwo5t0BfI/xl91q7LmCnRH1sKZQNQ9WQvEN6S+fgCxB4Tq+23mqwAAAABJRU5ErkJggg==) no-repeat 97% center;
}
:global .content-details .search-section a.action{
  margin-left: 5px;
}
:global .ant-modal-content .profile .ant-image-img{
  margin: 0 auto;
}
:global .ant-modal-mask{
  background: rgba(0, 119, 199, 0.47);
}
:global .ant-modal-content{
  border-radius: 30px;
}
:global .ant-modal-close{
  background: #0077C7;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  color: #FFF;
}
:global .ant-modal.remove-popup .ant-modal-close{
  right: -10px;
  top: -10px;
}
:global body .modal-body .modal-content-details .profile{
  margin-top: 15px;
}
:global .modal-content .modal-content-details ul{
  margin: 0 -30px;
  padding: 0 0 20px;
  list-style: none;
  width: calc(100% + 60px);
}
:global .modal-content .modal-content-details ul li{
  font-size: 18px;
  border-bottom: #ECECEC 1px solid;
  padding: 10px 30px;
  color: #000;
}
:global .modal-content .modal-content-details ul li a{
  margin: 0;
}
:global .modal-content .modal-content-details ul li:first-child{
  padding-top: 0;
}
:global .modal-content .modal-content-details ul li .date{
  min-width: 180px;
  display: inline-block;
}
:global .modal-content .modal-content-details ul li:last-child{
  border: none;
}
:global .ant-modal-body .remove-content{
  padding: 30px;
  text-align: center;
}
:global .ant-modal-body .remove-content p{
  font-size: 18px;
  color: #000;
}
:global .ant-modal-body .remove-content .modal-action button{
  background: linear-gradient(180deg, #0078C8 0%, #06488B 100%) 0% 0% no-repeat padding-box;
  min-width: inherit;
  font-size: 14px;
  line-height: 1;
  border-radius: 5px;
  padding: 7px 15px;
  margin: 0 3px;
  border: none;
  color: #FFF;
  text-transform: uppercase;
  line-height: 1;
  height: auto;
}
:global .ant-modal-body .remove-content .modal-action button.cancel{
  background: #999;
}
:global .ant-modal-body .remove-content .modal-action button:hover{
  color: #FFF;
  background: linear-gradient(180deg, #06488B 0%, #0078C8 100%) 0% 0% no-repeat padding-box;
}
:global .ant-modal-close-x{
  line-height: 36px;
  width: 36px;
  height: 36px;
  color: #FFF !important;
}
/* :global .ant-modal-close:hover{
  color: #FFF;
  background: #000;
} */
:global .ant-pagination-options{
  display: none;
}
:global .ant-image-mask{
  width: 112px;
  margin: 0 auto;
  border-radius: 50%;
}
:global .ant-modal-close-x:before{
  content: '\f00d';
  font-family: 'FontAwesome';
}
:global .ant-modal-close-x svg{
  display: none;
}
:global .ant-form .ant-input, :global .ant-form .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 30px;
}
:global .action-box button{
  min-width: 143px !important;
  padding: 0 !important;
  text-transform: none !important;
}
:global .ant-form-vertical .ant-form-item{
  width: calc(33.33% - 20px);
  display: block;
  margin: 0 !important;
}
:global .ant-upload-list-picture .ant-upload-list-item{
  height: auto;
  padding: 0;
  border: #707070 2px solid;
  margin: 0;
  border-radius: 50%;
}
:global .ant-upload-list-picture .ant-upload-list-picture-container{
  position: absolute;
  top: 0;
}
:global .ant-upload-list-picture:before{
  height: 124px;
  width: 124px;
  padding: 0;
  border: #707070 2px solid;
  margin: 0;
  border-radius: 50%;
}
:global .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-name{
  display: none;
}
:global .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail{
  width: 120px;
  height: 120px;
}
:global .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img{
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
:global .image-box span.ant-upload{
  width: 124px;
    position: absolute;
    text-align: center;
    z-index: 10;
    background: rgba(0,119,199,0.47);
    color: #FFF;
    bottom: 160px;
    cursor: pointer;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
:global .ant-modal-footer{
  text-align: center;
}
:global .ant-modal-footer .ant-btn.ant-btn-primary{
  min-width: inherit;
  padding: 0 20px;
  font-size: 16px;
}
:global .ant-modal-footer .ant-btn{
  background: linear-gradient(180deg, #0078C8 0%, #06488B 100%) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 20px;
    padding: 0 20px;
    font-size: 16px;
    color: #FFF;
}
:global .content-details .entry-card .form-box .image-box > span{
  display: flex;
  position: relative;
  align-items: center;
}
:global .ant-form-vertical .ant-form-item-label{
  padding-bottom: 0;
}