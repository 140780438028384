:global .customer-table .ant-table-content table thead th{
    width: auto;
}
/* :global .content-details .customer-table .ant-table-content table .ant-table-tbody tr td:nth-child(3)::first-letter{
	background: rgba(0, 69, 116, 0.4);
    font-size: 14px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    color: #FFF;
    text-transform: uppercase;
    display: block;
    padding: 0 5px;
    margin-right: 8px;
} */
/* :global .content-details .customer-table .ant-table-content table .ant-table-tbody tr td:nth-child(1), :global .content-details .customer-table .ant-table-content table .ant-table-tbody thead tr th:nth-child(1){
    border-right: none;
} */
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td{
    background: #EBEBEB;
    padding: 10px;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row .ant-pagination{
    margin: 20px 0 10px !important;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper{
    margin: 0;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper .ant-table{
    margin: 0;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper .ant-table .ant-table-content table{
    border-radius: 0;
    border: none;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper .ant-table .ant-table-content table thead tr th{
    background: #FFF;
    border-radius: 0 !important;
    padding: 15px 20px;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper .ant-table .ant-table-content table tbody tr td{
    padding: 10px 20px;
    background: #FFF;
    border-radius: 0;
}
:global .content-details .ant-table-content table .ant-table-tbody tr.ant-table-expanded-row td .ant-table-wrapper .ant-table .ant-table-content table tbody tr td:nth-child(3)::first-letter{
    background: none;
    font-weight: normal;
    padding: 0;
    margin: 0;
    color: inherit;
}
.uploded-documents{
    display: flex;
}