.steps-content {
  /* min-height: 200px; */
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  /* border: 1px dashed #e9e9e9;
  border-radius: 2px; */
}

.AntContent {
  justify-content: center;
  border-radius: 25px;
}

.step1,
.step2,
.step3 {
  display: flex;
  justify-content: center;
}

.heading-step {
  padding-right: 5px;
}

.content-step1 {
  justify-content: center;
  align-items: center;
}
.steps-action {
  margin-top: 24px;
}

.primmary-btn {
  display: flex;
  /* width: 100%; */
  margin: 0 auto;
  border: none;
  background-color: #04aa6d;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

/* .step {
  font-weight: 700;
}

.table-wrap {
  text-align: left;
  max-width: 500px;
  border: 1px solid black;
  border-radius: 10px;
}

.AntContent-main {
  display: flex !important;
  justify-content: center !important;
}

.table-row {
  padding: 20px;
  border-bottom: 1px solid black;
}

.table-row:last-child {
  border: none;
}

.submit-btn {
  width: 100%;
}

.qr-code-cnr {
  display: flex;
  justify-content: center;
} */
