:global .task-table .ant-table-content table thead tr th:first-child{
    border-top-left-radius: 10px;
}
:global .task-table .ant-table-content table thead tr th:last-child{
    width: 15%;
    border-top-right-radius: 10px;
}
:global .task-table .ant-table-content table tbody tr td{
    border-right: #ECECEC 1px solid;
    padding: 10px 30px;
}
:global .task-table .ant-table-content table thead tr th.centeralign, :global .task-table .ant-table-content table tbody tr td.centeralign{
    text-align: center;
}
:global .task-table .ant-table-content table thead tr th{
    padding: 15px 30px;
}
:global .task-table .ant-table-content table tbody tr td:first-child{
    border-left: #ECECEC 1px solid;
}
:global .task-table .ant-table-content table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}
:global .task-table .ant-table-content table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}
:global .task-table .ant-table-content table tbody tr:last-child td{
    border-bottom: #ECECEC 1px solid;
}
:global .small-modal .modal-dialog{
    max-width: 500px;
}
:global .small-modal .modal-dialog .full-width{
    width: 100%;
}
:global .small-modal .modal-dialog .modal-header{
    margin-bottom: 20px;
}
:global .small-modal .modal-dialog .modal-body .content-details{
    padding: 0 20px 20px;
}
:global .small-modal .modal-dialog .modal-body .content-details .input-boxes{
    display: flex;
    flex-flow: wrap;
    grid-row-gap: 15px;
}
:global .small-modal .modal-dialog .modal-body .content-details .input-boxes .ant-form-item-label{
    padding: 0 12px;
}
:global .small-modal .modal-dialog .modal-body .content-details .input-boxes label{
    color: #000;
    padding-bottom: 3px;
}
:global .small-modal .modal-dialog .modal-body .content-details .input-boxes input[type=text], :global .small-modal .modal-dialog .modal-body .content-details .input-boxes textarea{
    padding: 5px 15px;
}
:global .small-modal .modal-dialog .modal-body .content-details .input-boxes textarea{
    border-radius: 10px;
}
:global .small-modal .modal-dialog .modal-body .action-box{
    margin-top: 15px;
}
:global .small-modal .modal-dialog .modal-body .action-box button{
    margin-right: 160px;
}
:global .small-modal .modal-dialog .modal-body button.reset{
    right: 30px;
    bottom: 20px;
}
:global .small-modal.remove-popup .modal-header{
    position: absolute;
    margin: 0;
    padding: 0;
    right: -10px;
    top: -10px;
    width: 36px;
    height: 36px;
    background: none;
    z-index: 1;
}
:global .small-modal.remove-popup .btn-close{
    right: 0 !important;
    top: 0 !important;
}
:global .small-modal.remove-popup .remove-content{
    padding: 30px;
    text-align: center;
}
:global .small-modal.remove-popup .remove-content p{
    font-size: 18px;
    color: #000;
}