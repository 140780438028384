:global .ant-form-item {
  margin-bottom: 15px !important;
}

:global .confirmButton {
  margin-top: 40px;
}

:global .ant-col {
  flex: 1 0;
}

:global .linkButton {
  color: #707070 !important;
  border: none !important;
  background: none !important;
  font-size: 16px !important;
}
:global .linkButton:hover {
  color: #012f4e !important;
}

.step {
  font-weight: 700;
}

.table-wrap {
  text-align: left;
  max-width: 500px;
  border: 1px solid black;
  border-radius: 10px;
}

.abccc {
  display: flex !important;
  justify-content: center !important;
}

.table-row {
  padding: 20px;
  border-bottom: 1px solid black;
}

.table-row:last-child {
  border: none;
}

.submit-btn {
  width: 100%;
}
